
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable } from "mobx";

class TransactionDisplay {
  id = uuidv4();

  type: string;
  amount: number;
  symbol: string;
  date: number;

  constructor(type: string, amount: number, symbol: string, date: number) {
    makeAutoObservable(this);
    this.type = type;
    this.amount = amount;
    this.symbol = symbol;
    this.date = date;
  }

  getIconString(): string {
    const valueToReturn = '';

    return valueToReturn;
  }

}

export default TransactionDisplay;