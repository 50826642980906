/* eslint-disable @typescript-eslint/no-unused-vars */
import firebase from "firebase/app";
import { action, makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import app from "../../app";
import { getTypedObjectFromFirebase, _getCollectionReference } from "./fireStoreHelpers/fireStoreHelpers";
import "firebase/firestore";
import "firebase/auth";
import { User, UserFactory } from "../../models";
import { TokenDetails1155, TokenDetailsFactory1155 } from "@xdappsdao/nft-data-models/lib";
import NFTForSale, { NFTForSaleFactory } from "../../models/nft-for-sale/NFTForSale";
import NFTSold, { NFTSoldFactory } from "../../models/nft-sold/NFTSold";
import request, { get } from "superagent";
export interface IFirebaseStore {
  firebaseAppDefined: boolean;
  uid: string;
  db?: firebase.firestore.Firestore;
  fbFunctions?: firebase.functions.Functions;
}

export class FirebaseStore implements IFirebaseStore {
  firebaseAppDefined = false;
  uid = '';
  db?: firebase.firestore.Firestore;
  fbFunctions?: firebase.functions.Functions;
  id = uuidv4();

  constructor() {
    makeAutoObservable(this);
  }

  @action initializeFB = (): void => {
    setInterval(() => {
      if (!this.firebaseAppDefined) {
        if (firebase.app()) {
          this.db = firebase.firestore();
          //  this.fbFunctions = firebase.functions();
          this.startAuthListener();
          // this.startXRPListener();
          // this.startXLMListener();
          // this.startLTCListener();
          // this.startADAListener();
          // this.startDOGEListener();
          // this.startALGOListener();
          // this.startBCHListener();
          this.firebaseAppDefined = true;
        }
      }
    }, 100);
  };

  @action initializeUser = async (uid: string): Promise<void> => {
    const address = app.user.address;
    // console.log("initializeUser ", uid);
    //  console.log("address ", address);
    try {
      if (address != uid) {
        app.user.setIsAuthenticated(false);
        this.processLogoutRequest();
      } else {
        this.uid = uid;
        //  console.log("Is Logged in initializeUser ");
        const userData = await getTypedObjectFromFirebase<User>("users", uid);
        app.user.setIsAuthenticated(true);
        app.user.setUser(userData);
      }
    } catch (err) {
      console.log("LOGIN_ERROR", err);
    }
  };

  //TODO
  //Discuss the option of when signed messages are needed vs simply connecting the wallet.
  //Figure out why we are getting so many auth popup signature requests
  //Re-Route Page refresh to the home page to avoid some errors
  startAuthListener = (): void => {
    //  console.log("startAuthListener");
    firebase.auth().onAuthStateChanged(async (user) => {
      //  debugger;
      if (user) {
        //  console.log("Is Logged in startAuthListener");
        //   console.log("uid ", user.uid);
        app.user.setIsAuthenticated(true);
        await this.initializeUser(user.uid);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        //const uid = user.uid;
        // ...
      } else {
        //;
        //   console.log("startAuthListener Fired");
        //  console.log("Not Logged In");
        app.user.setIsAuthenticated(false);
        // User is signed out
        // ...
      }
    });
  };





  // startADAListener = () => {
  //   if (this.db) {
  //     const doc = this.db.collection('prices').doc('ada');
  //     doc.onSnapshot(docSnapshot => {
  //       const price = docSnapshot.data()?.last;
  //       app.prices.setADA(price);
  //     }, err => {
  //       console.log(`Encountered error: ${err}`);
  //     });
  //   }
  // }


  @action processLoginRequest = async (): Promise<string> => {
    const address = await app.web3.setAccounts();
    const messageToSign = "BestFTSO Authentication";
    const messageSignature = await app.web3.signPersonalMessage(
      address,
      messageToSign
    );
    try {
      const getAuthToken = firebase
        .functions()
        .httpsCallable("validateSignatureGetToken");
      const result = await getAuthToken({
        address: address,
        signature: messageSignature,
      });
      const token = result.data.token;
      // Read result of the Cloud Function.
      const loginResult = await this.db?.app
        .auth()
        .signInWithCustomToken(token);
      const uid = loginResult?.user?.uid;
      const isNewUser = loginResult?.additionalUserInfo?.isNewUser;
      if (isNewUser && uid) {
        this.registerNewUser(uid.toLowerCase());
      }
      console.log("uid ", uid);
      return uid ? uid : "";
    } catch (err) {
      console.error(err);
      return "Error";
    }
  };

  @action registerNewUser = async (uid: string): Promise<void> => {
    //  console.log("GH", uid);
    try {
      const userDocument = this.db?.collection("users").doc(uid);
      userDocument?.set({
        address: uid,
        userName: uid,
        avatar: "",
        registered: Date.now(),
      });
    } catch (err) {
      console.log("LOGOUT_ERROR", err);
    }
  };

  @action processLogoutRequest = async (): Promise<void> => {
    try {
      await firebase.auth().signOut();
      app.user.setUser(UserFactory.fromVoid());
      window.location.href = "/";
    } catch (err) {
      console.log("LOGOUT_ERROR", err);
    }
  };

  @action saveToDB = async <T>(
    collectionPath: string,
    documentUID: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dataToSave: any
  ): Promise<void> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.doc(documentUID).set({ ...dataToSave });
  };


  @action deleteDBDocument = async (
    collectionPath: string,
    documentUID: string
  ): Promise<void> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.doc(documentUID).delete();
  };

  @action getAllDocsInCollection = async (
    collectionPath: string
  ): Promise<firebase.firestore.DocumentData> => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return await firestoreCollection.get();
  };

  @action getNewFirestoreDocID = async (
    collectionPath: string
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > => {
    const firestoreCollection: firebase.firestore.CollectionReference =
      _getCollectionReference(collectionPath);
    return firestoreCollection.doc();
  };



  @action getForAllSaleListings = async (): Promise<NFTForSale[]> => {
    const listToReturn: NFTForSale[] = [];
    if (this.db) {
      try {
        const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').get();
        listingsRef.docs.map(async doc => {
          const listing = await NFTForSaleFactory.fromJSON(doc.data());
          if (listing.price) {
            //  console.log("listing", listing);
            listToReturn.push(listing);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };
  @action getForSaleListingsByMarketplaceId = async (marketplaceId: number): Promise<NFTForSale[]> => {
    const listToReturn: NFTForSale[] = [];
    if (this.db) {
      try {
        // const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('marketplaceId', '==', marketplaceId).orderBy("price").limit(5).get();
        const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('marketplaceId', '==', marketplaceId).orderBy("price").limit(10).get();
        listingsRef.docs.map(async doc => {
          const listing = await NFTForSaleFactory.fromJSON(doc.data());
          //    console.log("listing", listing);
          if (listing.price) {
            // console.log("listing", listing);
            // console.log("listingId", listing);
            listToReturn.push(listing);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };
  @action getLowestForSaleListingByMarketplaceId = async (marketplaceId: number): Promise<number> => {
    let valueToReturn = 0;
    if (this.db) {
      try {
        // const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('marketplaceId', '==', marketplaceId).orderBy("price").limit(5).get();
        const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('marketplaceId', '==', marketplaceId).orderBy("price").limit(1).get();
        listingsRef.docs.map(async doc => {
          const listing = NFTForSaleFactory.fromJSON(doc.data());
          if (listing.price) {
            valueToReturn = listing.price;
            // console.log("valueToReturn", valueToReturn);
            return valueToReturn;
          }
        });
      } catch (error) {
        console.log("error", error);
        return valueToReturn;
      }
      return valueToReturn;
    }
    return valueToReturn;
  };
  @action getAuctionBids = async (currencyPath: string): Promise<Record<string, unknown>[]> => {
    const listToReturn: Record<string, unknown>[] = [];
    if (this.db) {
      try {
        const collectionPath = `bestFTSO/collections/auctions/1/${currencyPath}`;
        const bidsRef = await this.db.collection(collectionPath).orderBy("amount", "desc").limit(10).get();
        bidsRef.docs.map(async doc => {
          const bid = doc.data();
          if (bid.amount) {
            listToReturn.push(bid);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };
  @action getForSaleListingsBySellerAddress = async (): Promise<NFTForSale[]> => {
    const listToReturn: NFTForSale[] = [];
    if (this.db) {
      try {
        // const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('marketplaceId', '==', marketplaceId).orderBy("price").limit(5).get();
        const listingsRef = await this.db.collection('bestFTSO/marketplace/forSale').where('seller', '==', app.user.address).get();
        listingsRef.docs.map(async doc => {
          const listing = await NFTForSaleFactory.fromJSON(doc.data());
          if (listing.price) {
            listToReturn.push(listing);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };
  @action getAllSoldListings = async (): Promise<NFTSold[]> => {
    const listToReturn: NFTSold[] = [];
    if (this.db) {
      try {
        const soldRef = await this.db.collection('bestFTSO/marketplace/sold').orderBy("blockNumber", "desc").limit(7).get();
        soldRef.docs.map(async doc => {
          const soldItem = await NFTSoldFactory.fromJSON(doc.data());
          if (soldItem.price) {
            listToReturn.push(soldItem);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };

  @action getSoldListingsByMarketplaceId = async (marketplaceId: number): Promise<NFTSold[]> => {
    const listToReturn: NFTSold[] = [];
    if (this.db) {
      try {
        const soldRef = await this.db.collection('bestFTSO/marketplace/sold').where('marketplaceId', '==', marketplaceId).orderBy("blockNumber", "desc").limit(7).get();
        // const soldRefResults = await soldRef.orderBy("blockNumber");
        soldRef.docs.map(async doc => {
          const soldItem = await NFTSoldFactory.fromJSON(doc.data());
          if (soldItem.price) {
            listToReturn.push(soldItem);
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    return listToReturn;
  };

  @action getQuestTokens = async (amountToGet: number): Promise<TokenDetails1155[]> => {
    const listToReturn: TokenDetails1155[] = [];
    if (this.db) {
      try {
        const collectionRef = this.db.collection('bestFTSO/quests/1');
        const queryRef = collectionRef.where('collectionItemNumber', '<=', amountToGet);
        const queryResults = await queryRef.orderBy('collectionItemNumber', 'desc').get();
        queryResults.docs.map(async doc => {
          const token = await TokenDetailsFactory1155.fromQuestRecord(doc.data());
          listToReturn.push(token);
        });
        return listToReturn;
      } catch (error) {
        console.log("error", error);
        return listToReturn;
      }
    } else {
      return listToReturn;
    }
  };

  @action getMemorialTokens = async (amountToGet: number): Promise<TokenDetails1155[]> => {
    const listToReturn: TokenDetails1155[] = [];
    if (this.db) {
      const collectionRef = this.db.collection('bestFTSO/collections/memorial');
      const queryRef = collectionRef.where('collectionItemNumber', '<=', amountToGet);
      const queryResults = await queryRef.orderBy('collectionItemNumber', 'desc').get();
      queryResults.docs.map(async doc => {
        const token = await TokenDetailsFactory1155.fromMemorialRecord(doc.data());
        listToReturn.push(token);
      });
    }
    return listToReturn;
  };

  @action getFCTokens = async (amountToGet: number): Promise<TokenDetails1155[]> => {
    const listToReturn: TokenDetails1155[] = [];
    if (this.db) {
      const collectionRef = this.db.collection('bestFTSO/collections/fc');
      const queryRef = collectionRef.where('collectionItemNumber', '<=', amountToGet);
      const queryResults = await queryRef.orderBy('collectionItemNumber', 'desc').get();
      queryResults.docs.map(async doc => {
        const token = await TokenDetailsFactory1155.fromMemorialRecord(doc.data());
        listToReturn.push(token);
      });
    }
    return listToReturn;
  };

  @action getXBirdsTokens = async (amountToGet: number): Promise<TokenDetails1155[]> => {
    const listToReturn: TokenDetails1155[] = [];
    if (this.db) {
      const collectionRef = this.db.collection('bestFTSO/collections/xbirds');
      const queryRef = collectionRef.where('collectionItemNumber', '<=', amountToGet);
      const queryResults = await queryRef.orderBy('collectionItemNumber', 'desc').get();
      queryResults.docs.map(async doc => {
        const token = await TokenDetailsFactory1155.fromMemorialRecord(doc.data());
        listToReturn.push(token);
      });
    }
    return listToReturn;
  };

  @action getOwnersTokens = async (): Promise<Record<string, unknown>> => {
    let tokens: Record<string, unknown>[] = [];
    let totalTokensOwned;
    if (app.user.address) {
      const endpoint = `https://us-central1-nft-db-7d313.cloudfunctions.net/getNFTData/owner/19/${app.user.address}`;
      await get(endpoint).then(async (response: request.Response) => {
        if (response.text !== 'No Results Found!') {
          tokens = JSON.parse(response.text);
          const apiResponse: Record<string, any> = JSON.parse(response.text) as Record<string, unknown>;
          totalTokensOwned = apiResponse.totalTokens as number;
          tokens = apiResponse.tokens as Record<string, unknown>[];
          // console.log("tokens ", tokens);
        }
      });
    }
    const dataToReturn = {
      totalTokensOwned: totalTokensOwned,
      tokens: tokens,
    };
    return dataToReturn;
  };

  @action getNFTPTokens = async (amountToGet: number): Promise<TokenDetails1155[]> => {
    const listToReturn: TokenDetails1155[] = [];
    if (this.db) {
      const collectionRef = this.db.collection('nftpItems/19/available');
      const queryRef = collectionRef.where('itemId', '<=', amountToGet);
      const queryResults = await queryRef.orderBy('itemId', 'desc').get();
      queryResults.docs.map(async doc => {
        const token = await TokenDetailsFactory1155.fromNFTPRecord(doc.data());
        listToReturn.push(token);
      });
    }
    return listToReturn;
  };


  @action getAuctionTokens = async (amountToGet: number): Promise<Record<string, unknown>[]> => {
    const listToReturn: Record<string, unknown>[] = [];
    // console.log("getAuctionTokens amountToGet", amountToGet);
    if (this.db) {
      const collectionRef = this.db.collection('bestFTSO/collections/auctions');
      const queryRef = collectionRef.where('auctionId', '<=', amountToGet);
      const queryResults = await queryRef.orderBy('auctionId', 'desc').get();
      queryResults.docs.map(async doc => {
        const token = doc.data();
        listToReturn.push(token);
      });
    }
    return listToReturn;
  };

}
