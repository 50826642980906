import { ethers } from 'ethers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';
import { handleApproveAll } from '../../../utils/marketplace-utils/approveAll';

const ListForSaleModal = observer((): JSX.Element => {
  const [isApproved, setIsApproved] = useState(false);

  const handleApproveAllClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await handleApproveAll(app.nfts.active.contractAddress, app.nftClub.marketplaceAddress, !isApproved);
    app.modal.closeModal();
  };

  const getApprovalButtons = (): JSX.Element => {
    return isApproved ? removeApprovalButton : <></>;
  };
  const removeApprovalButton = <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApproveAllClicked(e)}>
    Remove Approval
  </Button>;
  const getOperatorStatus = async () => {
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    try {
      const abi = ['function isApprovedForAll(address account, address operator) public view returns (bool) '];
      const erc1155Contract = new ethers.Contract(app.nfts.active.contractAddress, abi, prov);
      const result: boolean = await erc1155Contract.isApprovedForAll(app.user.address, app.nftClub.marketplaceAddress);
      setIsApproved(result);
    } catch (error) {
      console.log('error ', error);
    }
  };
  React.useEffect(() => {
    getOperatorStatus();
  }, [app.user.address]);

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">List For Sale</h4>
        <div className="available-amount">Available : <strong>{app.nfts.active.qtyOwned} Qty</strong></div>
      </div>
      <div className="modal-body">
        <p>
          Best FTSO is transitioning the marketplace operations to <strong> NFTSO </strong> Please visit <strong> NFTSO.xyz to list! </strong>
        </p>
      </div>
      <div className="modal-footer">
        <div>
          <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
            Close
          </Button>
        </div>
        <div>
          {/* {removeApprovalButton}
          {removeListingButton} */}
          {getApprovalButtons()}
        </div>

      </div>
    </Modal>
  );
});
export default ListForSaleModal;
