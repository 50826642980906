import { ethers } from "ethers";
import app from "../../app";



// remove item from sale
export const removeItemFromSale = async (listingId: number): Promise<void> => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const abi = ['function removeFromSale(uint256 _listingId) external'];
  try {
    const marketplaceContract = new ethers.Contract(app.nftClub.marketplaceAddress, abi, prov.getSigner());
    return await marketplaceContract.removeFromSale(listingId);
  } catch (error) {
    console.log(error);
  }
};
