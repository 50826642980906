import { makeAutoObservable } from "mobx";

export class User {
  id = uuidv4();
    address: string
    userName: string
    email: string
    avatar: string
    twitter: string
    url: string
    bio: string
    listView: boolean
    rightHanded: boolean

  constructor(address: string, userName: string, email: string, avatar: string, twitter: string, url: string, bio: string) {
    makeAutoObservable(this);
        this.address = address;
        this.userName = userName;
        this.email = email;
        this.avatar = avatar;
        this.twitter = twitter;
        this.url = url;
        this.bio = bio;
        this.listView = false;
        this.rightHanded = true;
    }
}

export class UserFactory {

    static fromVoid(): User {
        return new User('', '', '', '', '', '', '');
    }
}

function uuidv4() {
  throw new Error("Function not implemented.");
}

