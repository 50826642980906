import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

class NFTForSale {
  id = uuidv4();
  listingId = 0;
  marketplaceAddress = '';
  marketplaceId = 0;
  price = 0;
  qtyForSale = 0;
  seller = '';

  constructor() {
    makeAutoObservable(this);
  }
}

export default NFTForSale;

export class NFTForSaleFactory {
  static fromJSON(jsonItem: Record<string, any>): NFTForSale {
    const itemToReturn: NFTForSale = new NFTForSale();
    itemToReturn.listingId = jsonItem?.listingId;
    itemToReturn.marketplaceAddress = jsonItem?.marketplaceAddress;
    itemToReturn.marketplaceId = jsonItem?.marketplaceId;
    itemToReturn.price = jsonItem?.price;
    itemToReturn.qtyForSale = jsonItem?.qty;
    itemToReturn.seller = jsonItem?.seller;
    return itemToReturn;
  }
}

