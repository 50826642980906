import React from 'react';
import { observer } from 'mobx-react';
import app from '../../app';
import TermsConditionsModal from './terms-conditions/TermsConditionsModal';
import MetaMaskModal from './connect-metamask-modal/MetaMaskModal';
import DelegateModal from './delegate-modal/DelegateModal';
import WrapModal from './wrap-modal/WrapModal';
import UnWrapModal from './unwrap-modal/UnWrapModal';
import { ClaimModal } from './claim-modal/ClaimModal';
import TransferModal from './transfer-modal/TransferModal';
import AuctionModal from './auction-modal/AuctionModal';
import ApproveAllModal from './approve-all-modal/ApproveAllModal';
import ListForSaleModal from './list-for-sale-modal/ListForSaleModal';
import BuyModal from './buy-modal/BuyModal';
import { NFTpClaimModal } from './nftp-claim/NFTpClaimModal';
import BurnModal from './burn-modal/BurnModal';
import BidModal from './bid-modal/BidModal';
import ERC1155BurnModal from './my-collection-modals/ERC1155BurnModal';
import ERC721BurnModal from './my-collection-modals/ERC721BurnModal';
import ERC721TransferModal from './my-collection-modals/ERC721TransferModal';
import ERC1155TransferModal from './my-collection-modals/ERC1155TransferModal';
import { NFTpClaimBonusModal } from './nftp-claim/NFTpClaimBonusModal';
import WrapFlareModal from './wrap-modal/WrapFlareModal';
import DelegateModalFlare from './delegate-modal/DelegateModalFlare';
import UnWrapModalFlare from './unwrap-modal/UnWrapModalFlare';
import { ClaimModalFlare } from './claim-modal/ClaimModalFlare';
import { NFTpClaimModalFlare } from './nftp-claim/NFTpClaimModalFlare';

const ModalShared = observer((): JSX.Element => {
  switch (app.modal.modalToShow) {
    case 'legal':
      return <TermsConditionsModal />;
    case 'burn':
      return <BurnModal />;
    case 'metamaskNetwork':
      return <MetaMaskModal />;
    case 'delegate':
      return <DelegateModal />;
    case 'delegateFlare':
      return <DelegateModalFlare />;
    case 'wrap':
      return <WrapModal />;
    case 'wrapFlare':
      return <WrapFlareModal />;
    case 'listForSale':
      return <ListForSaleModal />;
    case 'unwrapFlare':
      return <UnWrapModalFlare />;
    case 'unwrap':
      return <UnWrapModal />;
    case 'approveAll':
      return <ApproveAllModal />;
    case 'transfer':
      return <TransferModal />;
    case 'auction':
      return <AuctionModal />;
    case 'buy':
      return <BuyModal />;
    case 'bid':
      return <BidModal />;
    case 'claim':
      return <ClaimModal />;
    case 'claimFlare':
      return <ClaimModalFlare />;
    case 'nftpClaim':
      return <NFTpClaimModal />;
    case 'nftpClaimFlare':
      return <NFTpClaimModalFlare />; 
    case 'nftpClaimBonus':
      return <NFTpClaimBonusModal />;
    case 'erc721Transfer':
      return <ERC721TransferModal />;
    case 'erc721Burn':
      return <ERC721BurnModal />;
    case 'erc1155Transfer':
      return <ERC1155TransferModal />;
    case 'erc1155Burn':
      return <ERC1155BurnModal />;
    default:
      return <></>;
  }
});
export default ModalShared;
