import { ethers } from 'ethers';
import React from 'react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';


const ERC721BurnModal = (): JSX.Element => {

  const handleBurnClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const abi = ['function burnToken(uint256 _tokenId) external'];
    try {
      const contractAddress = app.nfts.collectionToken.contractAddress as string;
      const tokenId = app.nfts.collectionToken.tokenId as number;
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const contract = new ethers.Contract(contractAddress, abi, prov.getSigner());
      await contract.burnToken(tokenId);
      app.modal.closeModal();

    } catch (error) {
      console.log('error ', error);
    }

    app.modal.closeModal();
  };

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Burn NFT</h4>
      </div>
      <div className="modal-body">
        <p>
          You are about to <strong> BURN your </strong> NFT.
        </p>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                If you burn this NFT, you will <strong>Never</strong> be able to recover it. <br />
                It will be gone <strong>forever</strong>.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
            Close
          </Button>
        </div>

        <div>
          <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleBurnClicked(e)}>
            Burn Now
          </Button>
        </div>

      </div>
    </Modal>
  );
};
export default ERC721BurnModal;