import { BigNumber } from "ethers";
import app from "../../app";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable } from "mobx";

//** NTF Item Class Model */
export class TokenPrice {
  id = uuidv4();
  nativePrice = 0;
  askingPrice: BigNumber = BigNumber.from(0);
  serviceFee: BigNumber = BigNumber.from(0);
  totalUserPays: BigNumber = BigNumber.from(0);
  amountToSeller: BigNumber = BigNumber.from(0);

  constructor(askingPrice: BigNumber) {
    makeAutoObservable(this);
    this.askingPrice = askingPrice;
    this._calculateFees();
  }

  getPriceInNative(): number {
    return parseFloat(app.web3.convertWEIToETH(this.askingPrice.toString()));
  }
  getFeeInNative(): number {
    return parseFloat(app.web3.convertWEIToETH(this.serviceFee.toString()));

  }
  getTotalPaidInNative(): number {
    return parseFloat(app.web3.convertWEIToETH(this.totalUserPays.toString()));

  }
  amountToSellerInNative(): number {
    return parseFloat(app.web3.convertWEIToETH(this.amountToSeller.toString()));

  }

  _calculateFees(): void {
    this.serviceFee = this.askingPrice.div(40);
    this.totalUserPays = this.askingPrice.add(this.serviceFee);
    this.amountToSeller = this.askingPrice.sub(this.serviceFee);
    if (app) {
      this.nativePrice = parseFloat(app.web3.convertWEIToETH(this.askingPrice.toString()));
    }
  }

}