// ** Router Import
import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import app from ".";
import { observer } from 'mobx-react';
import { routes } from "../utils";
import ModalShared from "../components/modal-shared/ModalShared";

const App = observer((): JSX.Element => {
  const [web3Initialized, setWeb3Initialized] = useState(false);

  //Initialize Web3 Window Listeners
  const setWindowListeners = async () => {
    if (window.ethereum !== undefined) {
      if (!web3Initialized) {
        window.ethereum.on('chainChanged', app.web3.setChainId);
        window.ethereum.on('accountsChanged', app.web3.setAccounts);
        await app.web3.initializeWeb3();
        setWeb3Initialized(true);
      }
      if (app.web3.isSongbirdNetwork()) {
        // await app.wallet.setRewardEpochData();
        app.wallet.setCurrentBalance();
        app.wallet.loadEpochData();
        app.modal.isOpen = false;
        app.modal.modalToShow = '';
        app.nftClub.setNFTPBalance();
      } else if (app.web3.isFlareNetwork()) {
        app.walletFlare.setCurrentBalance();
        app.walletFlare.loadEpochData();
        app.modal.isOpen = false;
        app.modal.modalToShow = '';
        app.nftClubFlare.checkClaimableNFTP();
      } else {
        app.web3.openSGBModal();
      }
    }
  };

  React.useEffect(() => {
    //Initialize Web3 Window Listeners
    //  window.addEventListener('DOMContentLoaded', setWindowListeners);
    app.fb.initializeFB();
    setWindowListeners();
    app.nfts.loadQuestTokens();
    app.nfts.loadNFTPTokens();
  }, [app.user.address, app.web3.chainId]);

  return (
    <Router>
      <ModalShared />
      <Switch>
        <Suspense
          fallback={
            <div className="main-body">
              <div>Loading... </div>
            </div>
          }
        >
          {routes.map((route, i) => {
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
        </Suspense>
      </Switch>
    </Router>
  );
});

export default App;
