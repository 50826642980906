// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";

export interface IVarsStore {
  baseURI: string;
  tokenContractAddress: string
  marketplaceContractAddress: string
  bridgeContractAddress: string
  pinataApiKey: string
  pinataApiSecret: string
}

export class VarsStore implements IVarsStore {
  id = uuidv4();
  baseURI = 'https://ipfs.io/ipfs/';
  tokenContractAddress = '';
  marketplaceContractAddress = '';
  bridgeContractAddress = '';
  pinataApiKey = '';
  pinataApiSecret = '';
  animations = [
    { duration: 1500, delay: 100 },
    { duration: 1000, delay: 100 },
    { duration: 1000, delay: 200 },
    { duration: 1000, delay: 200 },
    { duration: 1000, delay: 100 },
    { duration: 1000, delay: 100 },
    { duration: 1000, delay: 300 },
    { duration: 500, delay: 200 },
    { duration: 500, delay: 100 },
    { duration: 2000, delay: 500 },
    { duration: 500, delay: 200 },
    { duration: 500, delay: 100 },
    { duration: 500, delay: 0 },
    { duration: 500, delay: 0 },
    { duration: 500, delay: 100 },
    { duration: 500, delay: 0 }
  ];

  constructor() {
    makeAutoObservable(this);
  }


  @computed getImageUrlFromIPFSHash(ipfsHash: string): string {
    return this.baseURI + ipfsHash;
  }

}