import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';
import { burnItem, burnItemNewContract } from '../../../utils/marketplace-utils/burnItem';

const BurnModal = observer((): JSX.Element => {
  const [qtyToBurn, setQTY] = useState(1);

  const handleQtyToBuyChange = (newQty: string) => {
    const newAmount: number = parseInt(newQty);
    setQTY(newAmount);
  };
  const handleBurnClicked = async () => {
    if (app.nfts.active.contractAddress.toLowerCase() === '0x1646ac040284da5878dd85defb07ce4eb1e32c26') {
      await burnItem(app.nfts.active.contractAddress, app.nfts.active.tokenId, qtyToBurn);
    } else {
      await burnItemNewContract(app.nfts.active.contractAddress, app.nfts.active.tokenId, qtyToBurn);
    }
    console.log(app.nfts.active);
    app.modal.closeModal();
  };

  const truncateTo2Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(2);
  };

  const isValidToBurn = (): boolean => {
    return app.nfts.active.qtyOwned >= qtyToBurn;
  };

  const getBurnButton = (): JSX.Element => {
    return isValidToBurn() ? burnButton : <></>;
  };

  const burnButton = <Button color="default" type="button" onClick={() => handleBurnClicked()}>
    Burn Now
  </Button>;

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Burn NFTs</h4>
        <div className="available-amount">SGB Wallet Balance: <strong>{truncateTo2Digits(app.wallet.sgbBalance)} SGB</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to <strong> BURN {qtyToBurn} </strong> NFT(s).
        </p>
        <div className="inputlines">
          <FormGroup>
            <Row>
              <Col sm="5">
                <label>Qty to burn</label>
                <Input placeholder="Qty" id="amount" type="number" value={qtyToBurn} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQtyToBuyChange(e.target.value)} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                If you burn this NFT, you will <strong>Never</strong> be able to recover it. <br />
                It will be gone <strong>forever</strong>.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
            Close
          </Button>
        </div>

        <div>
          {getBurnButton()}
        </div>

      </div>
    </Modal>
  );
});
export default BurnModal;

