import { TokenDetails1155 } from '@xdappsdao/nft-data-models/lib';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';
import NFTForSale from '../../models/nft-for-sale/NFTForSale';
import NFTSold from '../../models/nft-sold/NFTSold';
import { removeItemFromSale } from '../../utils/marketplace-utils/removeItemFromSale';

export interface INFTsForSaleStore {
  nftsForSale: TokenDetails1155[];
  nftListings: NFTForSale[];
  userListings: NFTForSale[];
  nftSales: NFTSold[];
}

export class NFTsForSaleStore implements INFTsForSaleStore {
  id = uuidv4();
  auctionManagerAddress = "0xE16386f94e4602B314c7ab764d6539296b69E479";
  nftsForSale: TokenDetails1155[] = [];
  nftListings: NFTForSale[] = [];
  userListings: NFTForSale[] = [];
  nftSales: NFTSold[] = [];
  activeListing: NFTForSale = new NFTForSale();
  listOfXRPBids: Record<string, unknown>[] = [];
  listOfSGBBids: Record<string, unknown>[] = [];
  highestSGBBid = 13000;
  highestXRPBid = 5890;
  bidIsSGB = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action setBidCurrency(isSGB: boolean): void {
    this.bidIsSGB = isSGB;
    //console.log('nftListings', this.nftListings);
  }

  @action async loadListings(): Promise<void> {
    this.nftListings = await app.fb.getForSaleListingsByMarketplaceId(app.nfts.active.marketplaceId);
    //console.log('nftListings', this.nftListings);
  }
  @action setListOfSGBBids(listOfBids: Record<string, unknown>[]): void {
    this.listOfSGBBids = listOfBids;
  }
  @action setListOfXRPBids(listOfBids: Record<string, unknown>[]): void {
    this.listOfXRPBids = listOfBids;
  }
  @action async loadBids(): Promise<void> {
    await this.loadXRPBids();
    await this.loadSGBBids();
  }
  @action async loadXRPBids(): Promise<void> {
    const tempList = this.listOfXRPBids = await app.fb.getAuctionBids('bidsXRP');
    this.setListOfXRPBids(tempList);
    if (this.listOfXRPBids.length > 0) {
      this.highestXRPBid = this.listOfXRPBids[0].amount as number;
    }
  }
  @action async loadSGBBids(): Promise<void> {
    const tempList = await app.fb.getAuctionBids('bidsSGB');
    this.setListOfSGBBids(tempList);
    if (this.listOfSGBBids.length > 0) {
      this.highestSGBBid = this.listOfSGBBids[0].amount as number;
    }
  }
  @action async loadSellerListings(): Promise<void> {
    this.userListings = await app.fb.getForSaleListingsBySellerAddress();
    //console.log('nftListings', this.nftListings);
  }
  @action async loadSales(): Promise<void> {
    this.nftSales = await app.fb.getSoldListingsByMarketplaceId(app.nfts.active.marketplaceId);
    //console.log('nftSales', this.nftListings);
  }
  @action async handleBuyStarted(listingToBuy: NFTForSale): Promise<void> {
    this.activeListing = listingToBuy;
    app.modal.openModal('buy');
    //console.log('nftListings', listingToBuy);
  }
  @action async handleRemoveSaleClicked(listingToRemove: NFTForSale): Promise<void> {
    this.activeListing = listingToRemove;
    await removeItemFromSale(listingToRemove.listingId);
  }

  @computed getNFTListingsByMarketplaceId(idToGet: number): NFTForSale[] {
    const listToReturn: NFTForSale[] = [];
    for (let i = 0; i < this.nftListings.length; i++) {
      const itemForSale = this.nftListings[i];
      if (itemForSale.marketplaceId === idToGet) {
        listToReturn.push(itemForSale);
      }
    }
    return listToReturn;
  }

  @computed getNFTListingsBySellerAddress(): NFTForSale[] {
    const listToReturn: NFTForSale[] = [];
    for (let i = 0; i < this.nftListings.length; i++) {
      const itemForSale = this.nftListings[i];
      listToReturn.push(itemForSale);
    }
    return listToReturn;
  }

  truncate(num: number, places: number): number {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
  }


}