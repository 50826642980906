// For Firebase JS SDK v7.20.0 and later, measurementId is optional



export const firebaseConfig = {
  apiKey: "AIzaSyBc0WXJwYaTgBi9ec0MeKdPO7MlCfjUQnU",
  authDomain: "nft-bay.firebaseapp.com",
  projectId: "nft-bay",
  storageBucket: "nft-bay.appspot.com",
  messagingSenderId: "582572150727",
  appId: "1:582572150727:web:ec8c3a79cd34d0b31c53e2",
  measurementId: "G-9TS0CVM8GK"
};