import { BigNumber } from "ethers";
import app from "../../app";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable } from "mobx";

export class TokenListing {
  id = uuidv4();
 // contractAddress = '0x1646ac040284DA5878DD85DEfb07ce4eB1E32C26';
  marketplaceAddress = '0x3eD6b9037EFe4f8eFf52eB161eb2E9Ef7C52362d';
  marketplaceId: number
  listedQty = 0;
  listedPrice = 0;
  listingAddress: string;

  constructor(marketplaceId: number, listingAddress: string) {
    makeAutoObservable(this);
    this.marketplaceId = marketplaceId;
    this.listingAddress = listingAddress;
  }

  getTotalCostInWei(quantity: number): BigNumber {
    const valueToReturn = quantity * this.listedPrice;
    return app.web3.convertEthToWEI(valueToReturn.toString());
  }

}



