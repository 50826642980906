import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

const AuctionModal = (): JSX.Element => {
  
  const [xrpBid, setxrpBid] = useState('');
  const [sgbBid, setsgbBid] = useState('');


  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content auction-title">
        <h4 className="title title-up">Bid on Auction</h4>
        <div className="available-amount">Start Price: <strong>10,000 XRP/25,000 SGB</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to bid <strong>{xrpBid} XRP</strong> and/or <strong>{sgbBid} SGB</strong>
        </p>

        <div className="inputlines">
          <FormGroup>
          
            <Row>
              <Col sm="4">
              <label className="alignLabel">Your XRP Bid</label>
              </Col>
              <Col sm="8">
                <Input placeholder="10000" id="amount" type="number" value={xrpBid} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setxrpBid(e.target.value)} />
              </Col>
            </Row>
          
            <Row>
             <Col sm="4">
              <label className="alignLabel">Your SGB Bid</label>
              </Col>
              <Col sm="8">
                <Input placeholder="25000" id="qty" type="number" value={sgbBid} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsgbBid(e.target.value)} />
              </Col>
            </Row>

            <label>Your email address</label>

            <Row>
              <Col sm="12">
                <Input placeholder="Your email address" id="email" type="text"/>
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                You will pay <strong>1 SGB fee</strong> to put your bid. <strong>The best bidder will be contacted by NFT Riddler and BestFTSO team.</strong>.
              </p>
            </span>
          </div>
        </div>


      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        {/* <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApproveAllClicked(e)}>
          Approve All
        </Button> */}
        <Button color="default" type="button">
          Place a Bid
        </Button>
      </div>
    </Modal>
  );
};
export default AuctionModal;
