import React, { useState } from 'react';
import { Modal, FormGroup, Row, Col, Input, Button } from 'reactstrap';
import app from '../../../app';
import { burnItem, burnItemNewContract } from '../../../utils/marketplace-utils/burnItem';

const ERC1155BurnModal = (): JSX.Element => {
  const [qtyToBurn, setQTY] = useState(1);
  const handleQtyToBuyChange = (newQty: string) => {
    const newAmount: number = parseInt(newQty);
    setQTY(newAmount);
  };
  const handleBurnClicked = async () => {
    const contractAddress = app.nfts.collectionToken.contractAddress as string;
    const tokenId = app.nfts.collectionToken.tokenId as number;
    if (contractAddress.toLowerCase() === '0x1646ac040284da5878dd85defb07ce4eb1e32c26') {
      await burnItem(contractAddress, tokenId, qtyToBurn);
    } else {
      await burnItemNewContract(contractAddress, tokenId, qtyToBurn);
    }
    app.modal.closeModal();
  };

  const isValidToBurn = (): boolean => {
    const qtyOwned = app.nfts.collectionToken.qtyOwned as number;
    return qtyOwned >= qtyToBurn;
  };

  const getBurnButton = (): JSX.Element => {
    return isValidToBurn() ? burnButton : <></>;
  };

  const burnButton = <Button color="default" type="button" onClick={() => handleBurnClicked()}>
    Burn Now
  </Button>;
  return (<Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
    <div className="modal-header justify-content">
      <h4 className="title title-up">Burn NFTs</h4>
    </div>
    <div className="modal-body">
      <p>
        You are about to <strong> BURN {qtyToBurn} </strong> NFT(s).
      </p>
      <div className="inputlines">
        <FormGroup>
          <Row>
            <Col sm="5">
              <label>Qty to burn</label>
              <Input placeholder="Qty" id="amount" type="number" value={qtyToBurn} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQtyToBuyChange(e.target.value)} />
            </Col>
          </Row>
        </FormGroup>
      </div>
      <div>
        <div className="modaldetails small">
          <span>
            <p>
              If you burn this NFT, you will <strong>Never</strong> be able to recover it. <br />
              It will be gone <strong>forever</strong>.
            </p>
          </span>
        </div>
      </div>
    </div>
    <div className="modal-footer">
      <div>
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>
      </div>

      <div>
        {getBurnButton()}
      </div>

    </div>
  </Modal>
  );
};
export default ERC1155BurnModal;