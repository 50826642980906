import { BigNumber, ethers } from 'ethers';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

const UnWrapModal = (): JSX.Element => {
  const [amountToUnWrap, setAmountToUnWrap] = useState(0);
  const [percentageToUnWrap, setPercentageToUnWrap] = useState(0);

  const handleUnWrappedClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    try {
      const abi = ['function withdraw(uint256 _amount) external'];
      const delegateContract = new ethers.Contract(app.wallet.wSGBAddress, abi, prov.getSigner());
      const finalAmount: string = percentageToUnWrap === 100 ? app.wallet.wSGBBalanceFull : amountToUnWrap.toString();
      const amountToWD: BigNumber = app.web3.convertEthToWEI(finalAmount);
      await delegateContract.withdraw(amountToWD);
      //  console.log('result ', result)
      app.modal.closeModal();

    } catch (error) {
      console.log('error ', error);
    }

  };
  const handlePercentageClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, percentageClicked: number) => {
    e.preventDefault();
    setPercentageToUnWrap(percentageClicked);
    if (percentageClicked === 100) {
      const amount = app.wallet.wSGBBalance * (percentageClicked / 100);
      setAmountToUnWrap(amount);
    } else {
      setAmountToUnWrap(app.wallet.wSGBBalance);
    }
  };

  const handleChangePercent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPercentage: number = parseInt(e.target.value);
    if (newPercentage < 100) {
      setPercentageToUnWrap(newPercentage);
      const amount = app.wallet.wSGBBalance * (newPercentage / 100);
      setAmountToUnWrap(amount);
    } else {
      setAmountToUnWrap(app.wallet.wSGBBalance);
    }
  };

  const calcRemaining = (): number => {
    return parseFloat(truncateTo5Digits(app.wallet.sgbBalance + amountToUnWrap));
  };

  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(5);
  };

  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Un-Wrap</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.wallet.wSGBBalance)} WSGB</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to un-wrap <strong>Wrapped Songbird Tokens (WSGB)</strong> into  <strong>Songbird tokens (SGB)</strong>
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountToUnWrap(parseFloat(e.target.value))} value={amountToUnWrap} />
              </Col>
            </Row>

            <Row className="amount-adjustment">
              <Col sm="8">

                <Row>
                  <Col xs="3">
                    <Button color="default" className="level1" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 25)}>
                      25%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level2" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 50)}>
                      50%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level3" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 75)}>
                      75%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level4" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 100)}>
                      100%
                    </Button>
                  </Col>
                </Row>

              </Col>
              <Col sm="4" className="text-right">
                <Input min="0" placeholder="0%" id="percent" type="number" value={percentageToUnWrap} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePercent(e)} />{/*  Add a max value to 100% */}

              </Col>
            </Row>
          </FormGroup>

        </div>
        <br></br>
        <div className="modaldetails">
          <span className="left">You are un-wrapping</span>
          <span className="right">{truncateTo5Digits(amountToUnWrap)} WSGB</span>
        </div>
        <div className="modaldetails">
          <span className="left">SGB After(minus gas) </span>
          <span className="right">{calcRemaining()} SGB</span>
        </div>

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        {amountToUnWrap > 0 ? <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleUnWrappedClicked(e)}>
          Un-Wrap
        </Button> : <></>
        }

      </div>
    </Modal>
  );
};
export default UnWrapModal;
