

import { BigNumber, ethers } from 'ethers';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import { TokenPrice } from '../../models';
import app from '../../app';
import { BestNFT, BestNFTFactory } from '../../models/token/BestNFT';
import { TokenListing } from '../../models/token-listing/TokenListing';

export interface INFTClubStore {
  isClicked: boolean;
  price: TokenPrice;
  active?: BestNFT;
  currentView: string;
  bestListing?: TokenListing;
  nftpAddress: string;
  marketplaceAddress: string;
  claimableNFTP: number;
  nftpBalance: number;
}
export class NFTClubStore implements INFTClubStore {
  id = uuidv4();
  isClicked = false;
  active?: BestNFT;
  currentView = 'home';
  bestListing?: TokenListing;
  marketplaceAddress = '0x501C63e22c5A472785b316b3D18a033E6B589b5f';
  oldmarketplaceAddress = '0x3eD6b9037EFe4f8eFf52eB161eb2E9Ef7C52362d';
  oldOldMarketplaceAddress = '0xedafdb092A50cE56488ad679fDe35396dE7cEEa2';
  // tokenContract1155Address = '0x1646ac040284DA5878DD85DEfb07ce4eB1E32C26';
  //oldnftpAddress = '0x74158DAAa7600Fb5b1479607bEcc3Fcc6C49c1a3';
  nftpAddress = '0x3B46090e608cBC963356f30857F4DAcC09f5DDC4';
  nftpRedeemerAddress = '0xc882b55846108124717957cDA77923034E0A15eb';
  questRedeemerAddress = '0x3aDF60dE7c1c304Cf3AF5336525A8498BfB27198';
  oldRedeemerAddress = '0x802ff1CAb64CD2e863b48DD453450A86d09D89a7';

  price = new TokenPrice(BigNumber.from(0));
  claimableNFTP = 0;
  claimableBonusNFTP = 0;
  nftpBalance = 0;
  constructor() {
    makeAutoObservable(this);
  }

  @action setIsClicked(newValue: boolean): void {
    this.isClicked = newValue;
  }
  @action setNewNFTView(newView: string): void {
    this.currentView = newView;
  }
  @action async setActiveNFTByMarketplaceId(marketplaceId: number): Promise<void> {
    if (app.user.address && window.ethereum) {
      this.active = await BestNFTFactory.fromMarketplaceId(marketplaceId, app.user.address);
      this.bestListing = this.active.getLowestPriceListing();
    }
  }




  @action async setNFTPBalance(): Promise<void> {
    this.nftpBalance = await this.getBalanceOfNFTP();
  }
  async getBalanceOfNFTP(): Promise<number> {
    if (app.user.isConnected) {
      if (window.ethereum && app.user.address) {
        try {
          const abi = ['function balanceOf(address account) external view returns (uint256)'];
          const nftpContract = new ethers.Contract(this.nftpAddress, abi, app.web3.ethersProvider);
          const balance: BigNumber = await nftpContract.balanceOf(app.user.address);
          const valToReturn = parseFloat(app.web3.convertWEIToETH(balance.toString()));
          return parseFloat(valToReturn.toFixed(2));
        } catch (error) {
          console.log('error ', error);
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  @action setClaimableNFTP(newValue: number): void {
    this.claimableNFTP = newValue;
  }
  @action setClaimableBonusNFTP(newValue: number): void {
    this.claimableBonusNFTP = newValue;
  }
  @action async checkClaimableNFTP(): Promise<void> {
    await this.setNFTPBalance();
    if (app.user.address && window.ethereum) {
      const NFTP_ABI = [
        "function calculateClaimableRewards(address _user, uint256 _tokenRewardPerVPBPerDenominator) public view returns (uint256)",
        "function rewardClaimableForBlock(address _user, uint256 _vpBlock, uint256 _tokenRewardPerVPBPerDenominator) public view returns (uint256)"
      ];
      const nftpContract = new ethers.Contract(this.nftpAddress, NFTP_ABI, app.web3.ethersProvider);
      const claimableRewardsTotalBN = await nftpContract.calculateClaimableRewards(app.user.address, 7);
      const totalClaimable = parseFloat(ethers.utils.formatUnits(claimableRewardsTotalBN));
      const claimableNFTP = parseFloat(totalClaimable.toFixed(2));
      this.setClaimableNFTP(claimableNFTP);
    }
  }
  @action async checkClaimableBonusNFTP(): Promise<void> {
    if (app.user.address && window.ethereum) {
      const BONUS_ABI = [
        "function calculateBoostReceived(address _addressToCheck) external view returns(uint256)",
        "function calculateBoostPercentageReceived(address _addressToCheck) external view returns(uint256)"
      ];
      //if (this.claimableNFTP > 0) {
      const nftpContract = new ethers.Contract("0xC84490846AFEf9Ca5e70535cE993Fd8e4217F425", BONUS_ABI, app.web3.ethersProvider);
      const dailyBoostBN = await nftpContract.calculateBoostReceived(app.user.address);
      const percentageBoostBN = await nftpContract.calculateBoostPercentageReceived(app.user.address);
      const dailyBoost = parseFloat(app.web3.convertWEIToETH(dailyBoostBN.toString()));
      const percentage = percentageBoostBN.toNumber();
      const claimable = this.claimableNFTP + dailyBoost;
      const percentageAddOn = (claimable * percentage) / 10000;
      const totalClaimableWithBoost = claimable + percentageAddOn;
      const finalNumber = parseFloat(totalClaimableWithBoost.toFixed(2));
      this.setClaimableBonusNFTP(finalNumber - this.claimableNFTP);
      // }
    }
  }
  @computed userOwnsActive(): boolean {
    if (this.active) {
      return this.active.userOwnedQty > 0;
    } else {
      return false;
    }
  }
  @computed usersIsForSale(): boolean {
    if (this.active) {
      return this.active.userForSaleQty > 0;
    } else {
      return false;
    }
  }

  @computed nftHasQtyForSale(): boolean {
    if (this.active) {
      return this.active.totalForSaleQty > 0;
    } else {
      return false;
    }
  }

}