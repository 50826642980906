import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { getIsApprovedAll1155, balanceOf1155 } from '@xdappsdao/nft-data-models';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';
import { buyItem } from '../../../utils/marketplace-utils/buyItem';

const BuyModal = observer((): JSX.Element => {
  const [qtyToBuy, setQTY] = useState(1);
  const [sellerBal, setSellerBal] = useState(0);
  const [maxAllowed, setMaxAllowed] = useState(1);
  const [hasApproval, setHasApproval] = useState(false);

  // const getTotalCostInWei = (): BigNumber => {
  //   let formattedAmount: BigNumber = BigNumber.from(0);
  //   const total = app.forSale.activeListing.price * qtyToBuy;
  //   const formattedTotal = app.web3.convertEthToWEI(total.toString());
  //   formattedAmount = formattedTotal;
  //   return formattedAmount;
  // };
  // const getCostInWei = (amount: number): BigNumber => {
  //   return app.web3.convertEthToWEI(amount.toString());
  // };
  const checkSellerApproval = async () => {
    const isApproved = await getIsApprovedAll1155(app.nfts.active.contractAddress, app.forSale.activeListing.seller, app.nftClub.marketplaceAddress, app.web3.ethersProvider);
    setHasApproval(isApproved);
  };
  const checkSellerBalance = async () => {
    const sellerBalanceBN = await balanceOf1155(app.nfts.active.contractAddress, app.forSale.activeListing.seller, app.nfts.active.tokenId, app.web3.ethersProvider);
    setSellerBal(sellerBalanceBN.toNumber());
    if (!app.nfts.active.limitOne) {
      setMaxAllowed(sellerBalanceBN.toNumber());
    }
  };

  const getTotalCostInNative = (): number => {
    let formattedAmount = 0;
    formattedAmount = app.forSale.activeListing.price * qtyToBuy;
    return parseFloat(truncateTo2Digits(formattedAmount));
  };
  const handleQtyToBuyChange = (newQty: string) => {
    console.log('limitOne: ', app.nfts.active.limitOne);
    const newAmount: number = parseInt(newQty);
    if (newAmount > maxAllowed) {
      setQTY(maxAllowed);
    } else {
      setQTY(newAmount);
    }
  };
  const truncateTo2Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(2);
  };
  const handleBuyClicked = async () => {
    await buyItem(app.forSale.activeListing.listingId, qtyToBuy, app.forSale.activeListing.price);
    app.modal.closeModal();
  };

  const pricingDiv = <div>

    {/*  <p> Royalty Address: <strong> {royaltyAddress}</strong>  </p> */}

    <div className="modaldetails">
      <span className="left">List price(each):</span>
      <span className="right"> {app.nftClub.bestListing?.listedPrice} <strong>SGB</strong></span>
    </div>
    <div className="modaldetails">
      <span className="left">You will pay:</span>
      <span className="right"> {getTotalCostInNative()} <strong>SGB</strong></span>
    </div>
  </div>;

  const notificationDiv = <div>
    <div className="modaldetails small">
      <span>
        <p>
          In order to <strong>buy this NFT</strong> you need to add more SGB to your wallet.
        </p>
      </span>
    </div>
    <div className="modaldetails">
      <span className="left">List price:</span>
      <span className="right"> {app.nftClub.bestListing?.listedPrice} <strong>SGB</strong></span>
    </div>
  </div>;

  const getApproveNotificationDiv = () => {
    return getTotalCostInNative() <= app.wallet.sgbBalance ? pricingDiv : notificationDiv;
  };

  const isValidToBuy = (): boolean => {
    const priceToUse = app.forSale.activeListing ? app.forSale.activeListing.price : 0;
    const sellerOwns: boolean = sellerBal >= qtyToBuy;
    const priceIsValid = priceToUse > 0;
    const qtyIsValid = qtyToBuy > 0;
    const buyerIsNotOwner = app.forSale.activeListing.seller.toLowerCase() != app.user.address.toLowerCase();
    const hasBalance = app.wallet.sgbBalance >= (qtyToBuy * priceToUse);
    return priceIsValid && hasBalance && buyerIsNotOwner && hasApproval && sellerOwns && qtyIsValid;
  };

  const getBuyButton = (): JSX.Element => {
    return isValidToBuy() ? buyButton : getReasonText();
  };

/*   const handleRemovalRequest = async (): Promise<unknown> => {
    const dataToSave = {
      listingId: app.forSale.activeListing.listingId,
      contractAddress: app.nfts.active.contractAddress,
      tokenId: app.nfts.active.tokenId,
    };
    return await app.fb.db?.collection('removalRequests').doc(app.forSale.activeListing.listingId.toString()).set(dataToSave);
  }; */

  const getReasonText = (): JSX.Element => {
    const priceToUse = app.forSale.activeListing ? app.forSale.activeListing.price : 0;
    const hasBalance = app.wallet.sgbBalance >= (qtyToBuy * priceToUse);
    return hasBalance ? <>No Longer available</> : <>Insufficient Funds</>;
  };

/*   const getInstructions = (): JSX.Element => {
    return isValidToBuy() ? <></> : getInstructionsReason();
  };

  const getInstructionsReason = (): JSX.Element => {
    const priceToUse = app.forSale.activeListing ? app.forSale.activeListing.price : 0;
    const hasBalance = app.wallet.sgbBalance >= (qtyToBuy * priceToUse);
    return hasBalance ? <>It looks like this NFT is missing (transfered or approval removed by the owner) please try to refresh the page and if after a delay the NFT is still missing you can ask to {removeListed} from the database and chain.</> : <>Insufficient Funds</>;
  };
 */

/*   const removeListed = <span onClick={() => handleRemovalRequest()}>
    remove the listing
  </span>;
 */
  const buyButton = <Button color="default" type="button" onClick={() => handleBuyClicked()}>
    Buy Now
  </Button>;

  React.useEffect(() => {
    //Initialize Web3 Window Listeners
    checkSellerApproval();
    checkSellerBalance();
  }, [app.user.address, app.web3.chainId, maxAllowed]);
  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Buy NFT</h4>
        <div className="available-amount">SGB Wallet Balance: <strong>{truncateTo2Digits(app.wallet.sgbBalance)} SGB</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to <strong> Buy {qtyToBuy} </strong> NFT(s) <strong>for {getTotalCostInNative()} SGB </strong>
        </p>

    

        <div className="inputlines">
          <FormGroup>
            <Row>
              <Col sm="5">
                <label>Qty to Buy</label>
                <Input placeholder="Qty" id="amount" min={1} max={maxAllowed} type="number" value={qtyToBuy} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQtyToBuyChange(e.target.value)} />
              </Col>
              <Col sm="7">
                <label>Price for each copy</label>
                <Input placeholder="Price" id="price" type="number" defaultValue={app.forSale.activeListing.price} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        {getApproveNotificationDiv()}
        
     {/*    <div className="smallinstruction">{getInstructions()}</div> */}
      </div>
      <div className="modal-footer">
        <div>
          <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
            Close
          </Button>
        </div>

        <div>
          {getBuyButton()}
        </div>

      </div>
    </Modal>
  );
});
export default BuyModal;
