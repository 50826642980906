import { ethers } from "ethers";

export const handleApproveAll = async (tokenContractAddress: string, operatorAddress: string, isApproved: boolean): Promise<void> => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  try {
    const abi = ['function setApprovalForAll(address operator, bool approved) public'];
    const tokenContract = new ethers.Contract(tokenContractAddress, abi, prov.getSigner());
    return await tokenContract.setApprovalForAll(operatorAddress, isApproved);
  } catch (error) {
    console.log('error ', error);
  }
};