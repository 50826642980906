import { ethers } from 'ethers';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import app from '../../../app';


const ERC721TransferModal = (): JSX.Element => {
  const [toAddress, setToAddress] = useState('');

  const handleTransferClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const abi = ['function transferFrom(address from,address to,uint256 tokenId) public'];
    try {
      const contractAddress = app.nfts.collectionToken.contractAddress as string;
      const tokenId = app.nfts.collectionToken.tokenId as number;
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const contract = new ethers.Contract(contractAddress, abi, prov.getSigner());
      await contract.transferFrom(app.user.address, toAddress, tokenId);
      app.modal.closeModal();

    } catch (error) {
      console.log('error ', error);
    }
  };

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Transfer</h4>
        <div className="available-amount">Available: <strong>{app.nfts.collectionToken.qtyOwned} Qty</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to <strong>transfer your NFT</strong> to <strong>{toAddress}</strong>
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Transfer to Address</label>
            <Row>
              <Col sm="12">
                <Input placeholder="Address" id="amount" type="text" value={toAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToAddress(e.target.value)} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                Transferring this NFT will make it <strong>inaccessible to you and you will not be able to recover it</strong>.
              </p>
            </span>
          </div>
        </div>


      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>
        <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleTransferClicked(e)}>
          Transfer
        </Button>
      </div>
    </Modal>
  );
};
export default ERC721TransferModal;