import { ethers } from "ethers";

export const getCurrentRewardEpochNumber = async (ftsoManagerAddress: string): Promise<number> => {
  const abi = ['function getCurrentRewardEpoch() view returns (uint256)'];
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const contract = new ethers.Contract(ftsoManagerAddress, abi, provider);
    const result = await contract.getCurrentRewardEpoch();
    return result.toNumber();
  } else {
    return 0;
  }
};

export const getVotePowerBlockForEpoch = async (ftsoManagerAddress: string, votePowerBlock: number): Promise<number> => {
  const abi = ['function getRewardEpochVotePowerBlock(uint256 _rewardEpoch) view returns (uint256)'];
  if (window.ethereum && votePowerBlock > 0) {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const contract = new ethers.Contract(ftsoManagerAddress, abi, provider);
    const result = await contract.getRewardEpochVotePowerBlock(votePowerBlock);
    return result.toNumber();
  } else {
    return 0;
  }
};