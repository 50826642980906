import { ethers } from 'ethers';
import React from 'react';
import { FTSOVotePower } from 'tso-data-models';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

export const ClaimModal = (): JSX.Element => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');


  const handleClaimClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const vpReader = new FTSOVotePower(app.wallet.wSGBAddress, app.wallet.rewardsManagerAddress, prov);
      console.log('Epoch P ', app.wallet.previousEpochNumber);
      console.log('Epoch C', app.wallet.currentEpochNumber);
      await vpReader.claimRewards(app.user.address, [app.wallet.previousEpochNumber - 1, app.wallet.previousEpochNumber]);
      //console.log('results ', results);
      // console.log('undelegateAll result hash ', result.hash)
      app.modal.closeModal();
    } catch (error) {
      console.log('error ', error);
    }
  };

  const amountIsValid = (): boolean => {
    return app.wallet.previousEpochPendingRewards > 0;
  };

  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(5);
  };


  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Claim</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.wallet.previousEpochPendingRewards)}</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to Claim <strong>SGB</strong> from the<strong> rewards pool.</strong>
        </p>
        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" value={app.wallet.previousEpochPendingRewards} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div className="modaldetails">
          <span className="left">You will claim</span>
          <span className="right">{truncateTo5Digits(app.wallet.previousEpochPendingRewards)} SGB</span>
        </div>
        {/* <div className="modaldetails">
          <span className="left">Network fees</span>
          <span className="right">0.0012</span>
        </div> */}

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        <div>
          {amountIsValid() ? <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClaimClicked(e)}>
            Claim
          </Button> : <></>}

        </div>
      </div>
    </Modal>
  );
};
