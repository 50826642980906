import { ethers } from 'ethers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';
import { handleApproveAll } from '../../../utils/marketplace-utils/approveAll';

const ApproveAllModal = observer((): JSX.Element => {
  const [operatorAddress, setOperatorAddress] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  const [qtyBalance, setQTY] = useState(0);
  const [newApprovalStatusText, setNewApprovalStatusText] = useState('Approve');

  const handleApproveAllClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await handleApproveAll(app.nfts.active.contractAddress, app.nftClub.marketplaceAddress, !isApproved);
    app.modal.closeModal();
  };

  const handleAddressChange = async (newAddress: string) => {
    setOperatorAddress(newAddress);
    await getOperatorStatus(newAddress);
  };
  const handleApprovalStatusChange = async (newStatus: string) => {
    const result = newStatus === 'Approve';
  //  setNewApprovalStatus(result);
    if (result) {
      setNewApprovalStatusText('Approve');
    } else {
      setNewApprovalStatusText('Remove');
    }
  };


  const getApproveText = (): string => {
    return isApproved ? 'Un-Approve' : 'Approve';
  };

  const getOperatorStatus = async (addressToCheck: string) => {
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    console.log(addressToCheck.length);
    if (addressToCheck.length === 42 && app.user.address) {
      try {
        const abi = ['function isApprovedForAll(address account, address operator) public view returns (bool) '];
        const erc1155Contract = new ethers.Contract(app.nfts.active.contractAddress, abi, prov);
        const result: boolean = await erc1155Contract.isApprovedForAll(app.user.address, addressToCheck);
        //console.log('result!', result)
        setIsApproved(result);
      } catch (error) {
        console.log('error ', error);
      }
    }
  };

  const loadBalance = async () => {
    if (window.ethereum && app.user.address) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      try {
        const abi = ['function balanceOf(address account, uint256 id) public view returns (uint256)'];
        const erc1155Contract = new ethers.Contract(app.nfts.active.contractAddress, abi, prov);
        const result = await erc1155Contract.balanceOf(app.user.address, 7);
        setQTY(result.toNumber());
      } catch (error) {
        console.log('error ', error);
      }
    }
  };
  loadBalance();

  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Transfer</h4>
        <div className="available-amount">Available : <strong>{qtyBalance} Qty</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to Approve/Remove<strong> the address below</strong> to <strong>Transfer your NFT </strong> for you
        </p>
        <p>
          Address:<strong> {operatorAddress} </strong>
        </p>
        <p>
          Is Currently Approved: <strong> {isApproved.toString()} </strong>
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Operator Address</label>
            <Row>
              <Col sm="8">
                <Input placeholder="Address" id="amount" type="text" value={operatorAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e.target.value)} />
              </Col>
              <Col sm="4">
                <Input type="select" name="select" id="exampleSelect" value={newApprovalStatusText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleApprovalStatusChange(e.target.value)} >
                  <option value="Approve">Approve</option>
                  <option value="Remove">Remove</option>
                </Input>
              </Col>
            </Row>
          </FormGroup>
        </div>
        <br></br>

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        {/* <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApproveAllClicked(e)}>
          Approve All
        </Button> */}
        <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApproveAllClicked(e)}>
          {getApproveText()} All
        </Button>
      </div>
    </Modal>
  );
});
export default ApproveAllModal;
