import { ethers } from "ethers";
import app from "../../app";


// Buy item for sale 
export const burnItem = async (tokenContractAddress: string, tokenId: number, qtyToBurn: number): Promise<void> => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const abi = ['function burn(address account,uint256 id,uint256 value) public'];
  try {
    const tokenContract = new ethers.Contract(tokenContractAddress, abi, prov.getSigner());
    return await tokenContract.burn(app.user.address, tokenId, qtyToBurn);
  } catch (error) {
    console.log(error);
  }
};
// Buy item for sale
export const burnItemNewContract = async (tokenContractAddress: string, tokenId: number, qtyToBurn: number): Promise<void> => {
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const abi = ['function burn(uint256 _tokenId, uint256 _qtyToBurn) public'];
  try {
    const tokenContract = new ethers.Contract(tokenContractAddress, abi, prov.getSigner());
    return await tokenContract.burn(tokenId, qtyToBurn);
  } catch (error) {
    console.log(error);
  }
};