// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { User } from "../../models/user/user";
import app from "../../app";
import { getTypedObjectFromFirebase } from "../firebase/fireStoreHelpers/fireStoreHelpers";

export interface IUserStore {
  address: string;
  userName: string;
  email: string;
  avatar: string;
  twitter: string;
  url: string;
  bio: string;
  listView: boolean;
  rightHanded: boolean;
  isConnected: boolean;
  isAuthenticated: boolean;
}

export class UserStore implements IUserStore {
  id = uuidv4();
  isConnected = false;
  // isAuthenticated = false;
  address = "";
  userName = "";
  email = "";
  avatar = "";
  twitter = "";
  url = "";
  bio = "";
  listView = false;
  isAuthenticated = false;
  rightHanded = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action setUser = (userData: User): void => {
    this.address = userData.address;
    this.userName = userData.userName;
    this.email = userData.email;
    this.avatar = userData.avatar;
    this.twitter = userData.twitter;
    this.url = userData.url;
    this.bio = userData.bio;
    this.listView = false;
    this.rightHanded = true;
  };

  @action loadUserByAddress = async (address: string): Promise<void> => {
    const result: User = await getTypedObjectFromFirebase<User>('users', address);
    if (result) {
      this.setUser(result);
    }
  };

  @action setUserAvatar = (newAvatar: string): void => {
    this.avatar = newAvatar;
  };

  @action setUserName = (userName: string): void => {
    this.userName = userName;
  };
  @action setUserEmail = (email: string): void => {
    this.email = email;
  };
  @action setUserUrl = (url: string): void => {
    this.url = url;
  };
  @action setIsAuthenticated = (isAuth: boolean): void => {
    this.isAuthenticated = isAuth;
  };


  @action setUserBio = (bio: string): void => {
    this.bio = bio;
  };
  @action setUserTwitter = (twitter: string): void => {
    this.twitter = twitter;
  };
  @action saveUser = async (): Promise<void> => {
    const userToSave = {
      address: this.address,
      userName: this.userName,
      email: this.email,
      avatar: this.avatar,
      twitter: this.twitter,
      url: this.url,
      bio: this.bio,
      listView: this.listView,
      rightHanded: this.rightHanded,
    };
    await app.fb.saveToDB("users", this.address, userToSave);
  };
}
