import { ethers } from 'ethers';
import React from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

export const NFTpClaimBonusModal = (): JSX.Element => {

  const handleBonusClaimClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      try {
        const abi = ['function claimNFTPWithBoost() external'];
        const tokenContract = new ethers.Contract("0xC84490846AFEf9Ca5e70535cE993Fd8e4217F425", abi, prov.getSigner());
        const result = await tokenContract.claimNFTPWithBoost();
        console.log(result);
        const tx = await result.wait();
        console.log(tx);
        app.modal.closeModal();

      } catch (error) {
        console.log('error ', error);
      }
      // const vpReader = new FTSOVotePower(app.wallet.wSGBAddress, app.wallet.rewardsManagerAddress, prov);
      //const results = await vpReader.claimRewards(app.user.address, [1, 2, 3]);
      //console.log('results ', results);
      // console.log('undelegateAll result hash ', result.hash)
      app.modal.closeModal();
    } catch (error) {
      console.log('error ', error);
    }
  };

  const amountIsValid = (): boolean => {
    return app.nftClub.claimableNFTP > 0;
  };

  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(2);
  };

  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Claim</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.nftClub.claimableBonusNFTP + app.nftClub.claimableNFTP)}</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to claim <strong>{(app.nftClub.claimableNFTP + app.nftClub.claimableNFTP)} NFTps</strong> with Quest Boost.
        </p>
        <p>
          You <strong>will not </strong> be able to <strong>transfer/sell</strong> your quest NFTs for 7 days.
        </p>
        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" value={app.nftClub.claimableBonusNFTP + app.nftClub.claimableNFTP} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div className="modaldetails">
          <span className="left">You will claim</span>
          <span className="right">{truncateTo5Digits(app.nftClub.claimableBonusNFTP + app.nftClub.claimableNFTP)} NFTP</span>
        </div>
        {/* <div className="modaldetails">
          <span className="left">Network fees</span>
          <span className="right">0.0012</span>
        </div> */}

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        <div>
          {amountIsValid() ? <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleBonusClaimClicked(e)}>
            Claim
          </Button> : <></>}

        </div>
      </div>
    </Modal>
  );
};
