// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';
import { TokenDetails1155 } from '@xdappsdao/nft-data-models';

export interface INFTsStore {
  questNFTsToLoad: number;
  nftpNFTsToLoad: number;
  quests: TokenDetails1155[];
  nftp: TokenDetails1155[];
}

export class NFTsStore implements INFTsStore {
  id = uuidv4();
  questNFTsToLoad = 7;
  currentView = 'memorial';
  nftpNFTsToLoad = 74;
  totalTokensOwned = 0;
  active: TokenDetails1155 = new TokenDetails1155();
  quests: TokenDetails1155[] = [];
  memorial: TokenDetails1155[] = [];
  xBirds: TokenDetails1155[] = [];
  nftp: TokenDetails1155[] = [];
  auction: Record<string, unknown>[] = [];
  fc: TokenDetails1155[] = [];
  ownersTokens: Record<string, unknown>[] = [];
  collectionToken: Record<string, unknown> = {};
  showDetails = false;

  constructor() {
    makeAutoObservable(this);
  }


  @action async loadQuestTokens(): Promise<void> {
    this.quests = await app.fb.getQuestTokens(this.questNFTsToLoad);
  }
  @action async loadNFTPTokens(): Promise<void> {
    this.nftp = await app.fb.getNFTPTokens(this.nftpNFTsToLoad);
  }
  @action setActiveNFT(active: TokenDetails1155): void {
    this.active = active;
  }
  @action setShowDetails(newValue: boolean): void {
    this.showDetails = newValue;
  }
  @action setCollectionToken(token: Record<string, unknown>): void {
    this.collectionToken = token;
  }
  @action refreshTokens(): void {
    if (this.loadQuestTokens.length === 0) {
      app.nfts.loadNFTPTokens();
      app.nfts.loadQuestTokens();
    }
  }
  @action setNewNFTView(newView: number): void {
    this.refreshTokens();
    console.log(newView);
    if (newView === 0) {
      this.currentView = 'nftp';
    } else if (newView === 1) {
      this.currentView = 'quest';
    } 
  }
  @action async handleNFTClicked(tokenClicked: TokenDetails1155): Promise<void> {
    this.active = tokenClicked;
    this.currentView = 'store';
    await app.forSale.loadListings();
    await app.forSale.loadSales();
  }



  truncate(num: number, places: number): number {
    return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
  }


}

