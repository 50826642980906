import React from 'react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';


const MetaMaskModal = (): JSX.Element => {

  const handleSwitchClicked = () => {
    app.web3.addChain();
  };



  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()} data-backdrop="static" data-keyboard="false" >
      <div className="modal-header">
        <h4 className="title title-up text-center"><span>Action required: </span>Switch network</h4>
      </div>
      <div className="modal-body">
        <p>
          To be able to Delegate your SGB, you need to connect your wallet on the Songbird Canary Network.
        </p>

      </div>
      <div className="modal-footer justify-content-center">
        <Button color="danger" type="button" onClick={() => handleSwitchClicked()}>
          Connect my wallet and switch to Songbird Network
        </Button>


      </div>
    </Modal >
  );
};
export default MetaMaskModal;
