
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

class NFTSold {
  id = uuidv4();
  buyer = '';
  blockNumber = 0;
  listingId = 0;
  marketplaceAddress = '';
  marketplaceId = 0;
  price = 0;
  timeStamp = 0;
  txHash = '';
  qty = 0;
  seller = '';
  constructor() {
    makeAutoObservable(this);
  }
}

export default NFTSold;

export class NFTSoldFactory {
  static async fromJSON(jsonItem: Record<string, any>): Promise<NFTSold> {
    const itemToReturn: NFTSold = new NFTSold();
    itemToReturn.listingId = jsonItem?.listingId;
    itemToReturn.marketplaceAddress = jsonItem?.marketplaceAddress;
    itemToReturn.marketplaceId = jsonItem?.marketplaceId;
    itemToReturn.price = jsonItem?.price;
    itemToReturn.qty = jsonItem?.qty;
    itemToReturn.timeStamp = jsonItem?.timeStamp;
    itemToReturn.txHash = jsonItem?.txHash;
    itemToReturn.seller = jsonItem?.seller;
    itemToReturn.buyer = jsonItem?.buyer;
    itemToReturn.blockNumber = jsonItem?.blockNumber;
    return itemToReturn;
  }
}

