import { ethers } from 'ethers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

const BidModal = observer((): JSX.Element => {
  const [bidAmount, setBidAmount] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");

  const handleBidAmountChange = (newBidAmount: string) => {
    const bidAmount: number = parseInt(newBidAmount);
    setBidAmount(bidAmount);
  };
  const truncateTo2Digits = (valueToTruncate: number): string => {
    return valueToTruncate ? valueToTruncate.toFixed(2) : "0";
  };
  const getHighestBidText = (): string => {
    const currency = getBidCurrency();
    const amount = getHighestBidAmount();
    return `Current Highest Bid: ${truncateTo2Digits(amount)} ${currency}`;
  };
  const getHighestBidAmount = (): number => {
    return app.forSale.bidIsSGB ? app.forSale.highestSGBBid : app.forSale.highestXRPBid;
  };

  const getBidCurrency = (): string => {
    return app.forSale.bidIsSGB ? 'SGB' : 'XRP';
  };



  const handleBidClicked = (): string => {
    if (app.forSale.bidIsSGB) {
      handleSGBBid();
    } else {
      handleXRPBid();
    }
    return app.forSale.bidIsSGB ? 'SGB' : 'XRP';
  };

  const handleSGBBid = async () => {
    if (window.ethereum && app.user.address) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ["function handleNewSGBBid(uint256 _bidAmount) external payable"];
      const auctionContract = new ethers.Contract(app.forSale.auctionManagerAddress, abi, prov.getSigner());
      const formattedFee = ethers.utils.parseEther("1");
      const result = await auctionContract.handleNewSGBBid(bidAmount, { value: formattedFee });
      const dataToSave = {
        address: app.user.address.toLowerCase(),
        bidAmount: bidAmount,
        bidCurrency: getBidCurrency(),
        email: emailAddress,
        hash: result.hash,
      };
      try {
        await app.fb.db?.collection('bidders').doc(app.user.address.toLowerCase()).set(dataToSave);
      } catch (error) {

      }
    }
    app.modal.closeModal();
  };

  const handleXRPBid = async () => {
    if (window.ethereum && app.user.address) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ["function handleNewXRPBid(uint256 _bidAmount) external payable"];
      const auctionContract = new ethers.Contract(app.forSale.auctionManagerAddress, abi, prov.getSigner());
      const formattedFee = ethers.utils.parseEther("1");
      const result = await auctionContract.handleNewXRPBid(bidAmount, { value: formattedFee });
      const dataToSave = {
        address: app.user.address.toLowerCase(),
        bidAmount: bidAmount,
        bidCurrency: getBidCurrency(),
        email: emailAddress,
        hash: result.hash,
      };
      try {
        await app.fb.db?.collection('bidders').doc(app.user.address.toLowerCase()).set(dataToSave);
      } catch (error) {

      }
    }
    app.modal.closeModal();
  };


  const isValidToBuy = (): boolean => {
    const currentHighest = getHighestBidAmount();
    return bidAmount > currentHighest;
  };

  const getBuyButton = (): JSX.Element | string => {
    return isValidToBuy() ? bidButton : "Must Bid Higher Than Current Bid";
  };

  const bidButton = <Button color="default" type="button" onClick={() => handleBidClicked()}>
    Bid Now
  </Button>;

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Place Bid</h4>
        <div className="available-amount">{getHighestBidText()}</div>
      </div>
      <div className="modal-body">
        <p>
          You are about to <strong> Bid {bidAmount} {getBidCurrency()} </strong>
        </p>
        <div className="inputlines">
          <FormGroup>
            <Row>
              <Col sm="7">
                <label>Bid Amount</label>
                <Input placeholder="Bid Amount" id="amount" min={getHighestBidAmount()} type="number" value={bidAmount} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBidAmountChange(e.target.value)} />
              </Col>
            </Row>
            <label>Your email address</label>

            <Row>
              <Col sm="12">
                <Input value={emailAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailAddress(e.target.value)} placeholder="Your email address" id="email" type="text" />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                You are <strong> agreeing to buy this NFT</strong> for {bidAmount} {getBidCurrency()} if you are the winner.
                There is a fee of 1 SGB per bid.
              </p>
            </span>
          </div>
          <div className="modaldetails">
            <span className="left">Bid Amount:</span>
            <span className="right"> {bidAmount} <strong>{getBidCurrency()}</strong></span>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
            Close
          </Button>
        </div>

        <div>
          {getBuyButton()}
        </div>

      </div>
    </Modal >
  );
});
export default BidModal;
