import { BigNumber, ethers } from 'ethers';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

const WrapModal = (): JSX.Element => {
  const [amountToWrap, setAmountToWrap] = useState(0);
  const [percentageToWrap, setPercentageToWrap] = useState(0);

  const handleWrappedClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    try {
      const abi = ['function deposit() external payable'];
      const delegateContract = new ethers.Contract(app.wallet.wSGBAddress, abi, prov.getSigner());
      const amountToDeposit: BigNumber = app.web3.convertEthToWEI(amountToWrap.toString());
      await delegateContract.deposit({ value: amountToDeposit });
     // console.log('result ', result)
      app.modal.closeModal();

    } catch (error) {
      console.log('error ', error);
    }

  };
  const handlePercentageClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, percentageClicked: number) => {
    e.preventDefault();
    setPercentageToWrap(percentageClicked);
    const amount = app.wallet.sgbBalance * (percentageClicked / 100);
    setAmountToWrap(amount);
  };

  const handleChangePercent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPercentage: number = parseInt(e.target.value);
    if (newPercentage < 100) {
      setPercentageToWrap(newPercentage);
      const amount = app.wallet.sgbBalance * (newPercentage / 100);
      setAmountToWrap(amount);
    }
  };

  const warningDiv = <div>
    Warning, you will need a small amount of SGB in order to UnWrap from W-SGB to SGB. We suggest leaving at least 0.25 SGB in your wallet for future gas needs.
  </div>;

  const getWarningDiv = () => {
    const balRemaining = calcRemaining();
    return balRemaining < 0.25 ? warningDiv : <></>;
  };

  const calcRemaining = (): number => {
    return parseFloat(truncateTo5Digits(app.wallet.sgbBalance - amountToWrap));
  };

  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(5);
  };




  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Wrap</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.wallet.sgbBalance)} SGB</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to wrap <strong>Songbird Tokens (SGB)</strong> into  <strong>Wrapped Songbird tokens (WSGB)</strong>
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" value={amountToWrap} />
              </Col>
            </Row>

            <Row className="amount-adjustment">
              <Col sm="8">

                <Row>
                  <Col xs="3">
                    <Button color="default" className="level1" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 25)}>
                      25%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level2" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 50)}>
                      50%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level3" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 75)}>
                      75%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level4" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 99)}>
                      99%
                    </Button>
                  </Col>
                </Row>

              </Col>
              <Col sm="4" className="text-right">
                <Input min="0" placeholder="0%" id="percent" type="number" value={percentageToWrap} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePercent(e)} />{/*  Add a max value to 100% */}

              </Col>
            </Row>
          </FormGroup>


        </div>
        <div className="modaldetails warning">
          {getWarningDiv()}
        </div>
        <br></br>
        <div className="modaldetails">
          <span className="left">You are minting</span>
          <span className="right">{truncateTo5Digits(amountToWrap)} WSGB</span>
        </div>
        <div className="modaldetails">
          <span className="left">SGB Remaining </span>
          <span className="right">{calcRemaining()} SGB</span>
        </div>

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>



        <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleWrappedClicked(e)}>
          Wrap
        </Button>
      </div>
    </Modal>
  );
};
export default WrapModal;
