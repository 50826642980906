import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { firebaseConfig } from './@state/firebase/fireStoreHelpers/fbConfig';
import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/firestore'; // make sure you add this for firestore
import "./laapp";
firebase.initializeApp(firebaseConfig);
ReactDOM.render(
  <App />,
  document.getElementById('root')
);