import { BigNumber, ethers } from 'ethers';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';
//import axios from 'axios';
import { FTSOVotePower } from 'tso-data-models';
import { getCurrentRewardEpochNumber, getVotePowerBlockForEpoch } from '../../utils/vote-block-utils/voteBlockUtils';

export interface IWalletStoreFlare {
  wFLRAddress: string;
  wFLRBalance: number;
  wFLRBalanceFull: string;
  flrBalance: number;
  tsoTotalAmount: string;
  delegatedAmount: number;
  unDelegatedAmount: number;
  otherTSODelegatedAmount: number;
}

export class WalletStoreFlare implements IWalletStoreFlare {
  id = uuidv4();
  wFLRAddress = '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d';
  // tokenContract1155 = '0x1646ac040284DA5878DD85DEfb07ce4eB1E32C26';
  rewardsManagerAddress = '0x85627d71921AE25769f5370E482AdA5E1e418d37';
  tsoAddress = '0x510Da05b7bf9d7713752da662AEdb26770F30d19';

  wFLRBalance = 0;
  wFLRBalanceFull = '0';
  currentEpochNumber = 0;
  previousEpochNumber = 0;
  currentEpochVoteBlock = 0;
  previousEpochVoteBlock = 0;
  currentEpochPendingRewards = 0;
  previousEpochPendingRewards = 0;
  currentEpochDelegatedAmount = 0;
  previousEpochDelegatedAmount = 0;
  flrPrice = 0;
  tsoTotalAmount = '';
  flrBalance = 0;
  delegatedAmount = 0;
  unDelegatedAmount = 0;
  otherTSODelegatedAmount = 0;
  delegateTokenSymbol = '';
  wrapTokenSymbol = '';
  availableToDelegate = 0;


  constructor() {
    makeAutoObservable(this);
  }

  @action setActiveDelegateToken(delegateTokenSymbol: string): void {
    this.delegateTokenSymbol = delegateTokenSymbol;
  }
  @action setActiveWrapToken(tokenSymbol: string): void {
    this.wrapTokenSymbol = tokenSymbol;
  }
  @action async setwFLRBalance(): Promise<void> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      this.wFLRBalanceFull = await this.getBalanceOfwFLR();
      this.wFLRBalance = parseFloat(this.wFLRBalanceFull);
      const vpReader = new FTSOVotePower(this.wFLRAddress, this.rewardsManagerAddress, prov);
      const tempAmount = await vpReader.getVotePowerDelegatedFromAnAddress(app.user.address, this.tsoAddress);
      this.delegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount.toHexString()));
      this.availableToDelegate = this.wFLRBalance - this.delegatedAmount;
      const tempAmount2 = await vpReader.getUnDelegatedVotePowerOf(app.user.address);
      this.unDelegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount2.toHexString()));
      this.otherTSODelegatedAmount = this.wFLRBalance - this.delegatedAmount - this.unDelegatedAmount;
      const tempDelegatedAmount = await vpReader.getTotalVotePowerOfAnAddress(this.tsoAddress);
      const valueToTruncate = parseFloat(app.web3.convertWEIToETH(tempDelegatedAmount.toString()));
      this.tsoTotalAmount = valueToTruncate.toFixed(0);
    }
  }

  @action setCurrentEpoch(epochNumber: number): void {
    this.currentEpochNumber = epochNumber;
  }
  @action setPreviousEpoch(epochNumber: number): void {
    this.previousEpochNumber = epochNumber;
  }
  @action setCurrentEpochVoteBlockNumber(epochVoteBlockNumber: number): void {
    this.currentEpochVoteBlock = epochVoteBlockNumber;
  }
  @action setPreviousEpochVoteBlockNumber(epochVoteBlockNumber: number): void {
    this.previousEpochVoteBlock = epochVoteBlockNumber;
  }

  @action async setEpochVotePowerHistory(): Promise<void> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      //  const abi = ['function delegatesOfAt(address _who, uint256 _blockNumber) external view returns(address[] memory _delegateAddresses, uint256[] memory _bips, uint256 _count, uint256 _delegationMode)'];
      this.wFLRBalanceFull = await this.getBalanceOfwFLR();
      this.wFLRBalance = parseFloat(this.wFLRBalanceFull);
      const vpReader = new FTSOVotePower(this.wFLRAddress, this.rewardsManagerAddress, prov);
      if (app.user.address) {
        const tempAmount4 = await vpReader.getVotePowerDelegatedFromAnAddressAtBlock(app.user.address, this.tsoAddress, this.previousEpochVoteBlock);
        this.previousEpochDelegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount4.toHexString()));
        const tempAmount5 = await vpReader.getVotePowerDelegatedFromAnAddressAtBlock(app.user.address, this.tsoAddress, this.currentEpochVoteBlock);
        this.currentEpochDelegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount5.toHexString()));
      }
    }
  }

  @action async setFLRBalance(newBalance: number): Promise<void> {
    this.flrBalance = newBalance;
  }

  @action async getPendingRewards(): Promise<void> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const vpReader = new FTSOVotePower(this.wFLRAddress, this.rewardsManagerAddress, prov);
      const results = await vpReader.rewardsManagerContract.getStateOfRewards(app.user.address, this.previousEpochNumber);
      const results2 = await vpReader.rewardsManagerContract.getStateOfRewards(app.user.address, this.currentEpochNumber);
      const alreadyClaimed = results[2][0];

      //console.log(results[2][0])
      //  console.log(results[3])
      const listOfRewardAmounts2 = results[1];
      const listOfRewardAmounts3 = results2[1];


      let value1 = BigNumber.from(0);
      if (listOfRewardAmounts2.length > 0) {
        for (let i = 0; i < listOfRewardAmounts2.length; i++) {
          if (!alreadyClaimed) {
            const rewardAmount: BigNumber = listOfRewardAmounts2[i];
            value1 = value1.add(rewardAmount);
          }
        }
      }
      this.previousEpochPendingRewards = parseFloat(app.web3.convertWEIToETH(value1.toString()));

      let value2 = BigNumber.from(0);
      if (listOfRewardAmounts3.length > 0) {
        for (let i = 0; i < listOfRewardAmounts3.length; i++) {
          const rewardAmount: BigNumber = listOfRewardAmounts3[i];
          value2 = value2.add(rewardAmount);
        }
      }
      this.currentEpochPendingRewards = parseFloat(app.web3.convertWEIToETH(value2.toString()));
    }
  }

  @computed getSongBirdClassString(): string {
    return this.flrBalance > 0 ? '' : 'zero-balance';
  }

  @computed async getRewardsEpochVoteBlockNumber(epochNumber: number): Promise<number> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const ftsoManagerAddress = '0x2E99a4543F9ea708Cf8CCaC447515e61706D9DE9';
      const abi = ['function getRewardEpochVotePowerBlock(uint256 _rewardEpoch) external view returns (uint256)'];
      const ftsoManager = new ethers.Contract(ftsoManagerAddress, abi, prov);
      const result2 = await ftsoManager.getRewardEpochVotePowerBlock(epochNumber);
      const voteBlock = result2.toNumber();
      return voteBlock;
    } else {
      return 0;
    }
  }

  @computed async getCurrentRewardsEpoch(): Promise<number> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const ftsoManagerAddress = '0x2E99a4543F9ea708Cf8CCaC447515e61706D9DE9';
      const abi = ['function getCurrentRewardEpoch() external view returns (uint256)'];
      const ftsoManager = new ethers.Contract(ftsoManagerAddress, abi, prov);
      const result = await ftsoManager.getCurrentRewardEpoch();
      return result.toNumber();
    } else {
      return 0;
    }
  }


  @action async setRewardEpochData(): Promise<void> {
    const currentEpoch = await this.getCurrentRewardsEpoch();
    this.setCurrentEpoch(currentEpoch);
    const previousEpochNumber = currentEpoch - 1;
    this.setPreviousEpoch(previousEpochNumber);
    const currentEpochVoteBlock = await this.getRewardsEpochVoteBlockNumber(this.currentEpochNumber);
    this.setCurrentEpochVoteBlockNumber(currentEpochVoteBlock);
    const previousEpochVoteBlock = await this.getRewardsEpochVoteBlockNumber(this.previousEpochNumber);
    this.setPreviousEpochVoteBlockNumber(previousEpochVoteBlock);
  }
  @computed getTSOTotalVotes(): string {
    return parseInt(this.tsoTotalAmount).toLocaleString();
  }

  @computed maxDelegationAvailable(): string {
    return this.flrBalance > 0 ? '' : 'zero-balance';
  }
  @computed getWrappedSongBirdClassString(): string {
    return this.wFLRBalance > 0 ? '' : 'zero-balance';
  }
  @computed getDelegatedPercentage(): number {
    const percentage = this.delegatedAmount / this.wFLRBalance;
    const valueToReturn = percentage * 100;
    if (valueToReturn > 0) {
      return parseInt(valueToReturn.toFixed(0));
    } else {
      return 0;
    }
  }

  @computed getUnDelegatedPercentage(): number {
    const percentage = this.unDelegatedAmount / this.wFLRBalance;
    const valueToReturn = percentage * 100;
    if (valueToReturn > 0) {
      return parseInt(valueToReturn.toFixed(0));
    } else {
      return 0;
    }
  }

  @computed getOtherTSODelegatedPercentage(): number {
    const percentage = this.otherTSODelegatedAmount / this.wFLRBalance;
    const valueToReturn = percentage * 100;
    if (valueToReturn > 0) {
      return parseInt(valueToReturn.toFixed(0));
    } else {
      return 0;
    }
  }

  async getBalanceOfwFLR(): Promise<string> {
    if (window.ethereum && app.user.address) {
      if (app.user.isConnected) {
        try {
          const abi2 = ['function balanceOf(address account) external view returns (uint256)'];
          const delegateContract = new ethers.Contract(this.wFLRAddress, abi2, app.web3.ethersProvider);
          const balance: BigNumber = await delegateContract.balanceOf(app.user.address);
          const valToReturn = app.web3.convertWEIToETH(balance.toString());
          return valToReturn;
        } catch (error) {
          console.log('error ', error);
          return '0';
        }
      } else {
        return '0';
      }
    } else {
      return '0';
    }

  }
  async loadEpochData(): Promise<unknown> {
    //get current epoch number
    const ftsoManagerAddress = "0x2E99a4543F9ea708Cf8CCaC447515e61706D9DE9";
    const currentEpoch = await getCurrentRewardEpochNumber(ftsoManagerAddress);
    // set values
    console.log('currentEpoch', currentEpoch);
    this.setCurrentEpoch(currentEpoch);
    this.setPreviousEpoch(currentEpoch - 1);
    // get vote blocks from epoch numbers and set values
    const currentEpochVotePowerBlock = await getVotePowerBlockForEpoch(ftsoManagerAddress, currentEpoch);
    const previousEpochVotePowerBlock = await getVotePowerBlockForEpoch(ftsoManagerAddress, currentEpoch - 1);
    this.setCurrentEpochVoteBlockNumber(currentEpochVotePowerBlock);
    this.setPreviousEpochVoteBlockNumber(previousEpochVotePowerBlock);
    this.setwFLRBalance();
    this.setEpochVotePowerHistory();
    this.getPendingRewards();
    return;
  }


  @action setCurrentBalance = async (): Promise<void> => {
    if (window.ethereum && app.user.address) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      console.log('app.user.address ', app.user.address);
      const bal = await provider.getBalance(app.user.address);
      const formattedBalance = ethers.utils.formatEther(bal).toString();
      //app.wallet.setEpochDetails();
      this.setFLRBalance(parseFloat(formattedBalance));
      // app.wallet.getPendingRewards();
      // app.wallet.setSGBPrice();
    }
  }
}