import { lazy } from "react";

const HomePage = lazy(() => import("../../pages/home/HomePage"));
const CollaborationPage = lazy(() => import("../../pages/collaboration/CollaborationPage"));
const BonusesPage = lazy(() => import("../../pages/bonuses/BonusesPage"));
const EarningsPage = lazy(() => import("../../pages/earnings/EarningsPage"));
const FAQPage = lazy(() => import("../../pages/faq/FAQPage"));
const FAssetsPage = lazy(() => import("../../pages/f-assets/FAssetsPage"));
const SAssetsPage = lazy(() => import("../../pages/s-assets/SAssetsPage"));
const NFTSPage = lazy(() => import("../../pages/nfts/NFTSPage"));
const ForSaleDetailsPage = lazy(() => import("../../pages/for-sale-details/ForSaleDetailsPage"));
const DelegatePage = lazy(() => import("../../pages/delegate/DelegatePage"));

export const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    secret: false,
    component: HomePage,
    menu: false,
    icon: "",
  },
  {
    path: "/details",
    name: "Detail",
    exact: true,
    secret: false,
    component: ForSaleDetailsPage,
    menu: false,
    icon: "",
  },
  {
    path: "/delegate",
    name: "Delegate",
    exact: true,
    secret: false,
    component: DelegatePage,
    menu: false,
    icon: "",
  },
  {
    path: "/bonuses",
    name: "Bonuses",
    exact: false,
    secret: false,
    component: BonusesPage,
    menu: false,
    icon: "",
  },
  {
    path: "/earnings",
    name: "Earnings",
    exact: false,
    secret: false,
    component: EarningsPage,
    menu: false,
    icon: "",
  },
  {
    path: "/f-assets",
    name: "F-Assets",
    exact: false,
    secret: false,
    component: FAssetsPage,
    menu: false,
    icon: "",
  },
  {
    path: "/s-assets",
    name: "S-Assets",
    exact: false,
    secret: false,
    component: SAssetsPage,
    menu: false,
    icon: "",
  },
  {
    path: "/nfts",
    name: "NFTs",
    exact: false,
    secret: false,
    component: NFTSPage,
    menu: false,
    icon: "",
  },
  {
    path: "/faq",
    name: "FAQs",
    exact: false,
    secret: false,
    component: FAQPage,
    menu: false,
    icon: "",
  },
  {
    path: "/collaboration",
    name: "Collaboration",
    exact: false,
    secret: false,
    component: CollaborationPage,
    menu: false,
    icon: "",
  },
];
