import React from 'react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';

const TermsConditionsModal = (): JSX.Element => {
  return (
    <Modal className="largeModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content-center">
        <button className="close" onClick={() => app.modal.closeModal()}>
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h4 className="title title-up">About BestFTSO</h4>
      </div>
      <div className="modal-body">
        <div className="inputlines">
          <p>
            .
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>
      </div>
    </Modal >
  );
};
export default TermsConditionsModal;
