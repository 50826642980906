/* eslint-disable multiline-ternary */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, makeAutoObservable } from 'mobx';
import app from '../../app';
import { v4 as uuidv4 } from 'uuid';

export interface IModalStore {
  modalToShow: string;
}

export class ModalStore implements IModalStore {
  id = uuidv4();
  isOpen = false;
  modalToShow = '';
  constructor() {
    makeAutoObservable(this);
  }

  @action closeModal(): void {
    this.isOpen = false;
    this.modalToShow = '';
  }
  @action openMetaMaskNetworkModal(): void {
    this.isOpen = true;
    this.modalToShow = 'metamaskNetwork';
  }
  @action openModal(modalToOpen: string): void {
    this.isOpen = true;
    this.modalToShow = modalToOpen;
  }
  @action handleListForSaleClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'listForSale';
  }
  @action handleClaimClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'claim';
  }
  @action handleClaimFlareClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'claimFlare';
  }
  @action handleMemorialNFTClicked(cardNumberClicked: number): void {
    this.isOpen = true;
    this.modalToShow = `memorialnft${cardNumberClicked.toString()}`;
  }
  @action handleNFTpNFTClicked(cardNumberClicked: number): void {
    this.isOpen = true;
    this.modalToShow = `nftpnft${cardNumberClicked.toString()}`;
  }
  @action handleTransferClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'transfer';
  }
  @action handleAuctionClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'auction';
  }
  @action handleApproveAllClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'approveAll';
  }
  
  @action handleExclusiveNFTClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'exclusivenft1';
  }

  @action handleOtherNFT1Clicked(): void {
    this.isOpen = true;
    this.modalToShow = 'othernft1';
  }
  
  @action handleDelegateFlareClicked(): void {
    if (app.walletFlare.wFLRBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'delegateFlare';
    }
  }
  @action handleDelegateClicked(): void {
    if (app.wallet.wSGBBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'delegate';
    }
  }
  @action handleWrapClicked(): void {
    if (app.wallet.sgbBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'wrap';
    }
  }
  @action handleWrapFlareClicked(): void {
    if (app.walletFlare.flrBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'wrapFlare';
    }
  } 
  @action handleUnWrapClicked(): void {
    if (app.wallet.wSGBBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'unwrap';
    }
  }
  @action handleUnWrapFlareClicked(): void {
    if (app.walletFlare.wFLRBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'unwrapFlare';
    }
  }



}
