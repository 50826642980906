

import { BigNumber, ethers } from 'ethers';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import app from '../../app';

export interface INFTClubStoreFlare {
  nftpAddress: string;
  claimableNFTP: number;
  nftpBalance: number;
}
export class NFTClubStoreFlare implements INFTClubStoreFlare {
  id = uuidv4();
  nftpAddress = '0xe8e84D158AFbdc125F17289d31e2bBBd057DEB6f';
  tsoAddress = '0x510Da05b7bf9d7713752da662AEdb26770F30d19';
  claimableNFTP = 0;
  nftpBalance = 0;
  constructor() {
    makeAutoObservable(this);
  }

  @action async setNFTPBalance(): Promise<void> {
    this.nftpBalance = await this.getBalanceOfNFTP();
  }
  async getBalanceOfNFTP(): Promise<number> {
    if (app.user.isConnected) {
      if (window.ethereum && app.user.address) {
        try {
          const abi = ['function balanceOf(address account) external view returns (uint256)'];
          const nftpContract = new ethers.Contract(this.nftpAddress, abi, app.web3.ethersProvider);
          const balance: BigNumber = await nftpContract.balanceOf(app.user.address);
          const valToReturn = parseFloat(app.web3.convertWEIToETH(balance.toString()));
          return parseFloat(valToReturn.toFixed(2));
        } catch (error) {
          console.log('error ', error);
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  @action setClaimableNFTP(newValue: number): void {
    this.claimableNFTP = newValue;
  }
  @action async checkClaimableNFTP(): Promise<void> {
    await this.setNFTPBalance();
    if (app.user.address && window.ethereum) {
      const NFTP_ABI = [
        "function calculateClaimableRewards(address _user, uint256 _tokenRewardPerVPBPerDenominator, address _tsoAddress) public view returns (uint256)",
        "function rewardClaimableForBlock(address _user, uint256 _vpBlock, uint256 _tokenRewardPerVPBPerDenominator, address _tsoAddress) public view returns (uint256)"
      ];
      const nftpContract = new ethers.Contract(this.nftpAddress, NFTP_ABI, app.web3.ethersProvider);
      const claimableRewardsTotalBN = await nftpContract.calculateClaimableRewards(app.user.address, 4, this.tsoAddress);
      const totalClaimable = parseFloat(ethers.utils.formatUnits(claimableRewardsTotalBN));
      const claimableNFTP = parseFloat(totalClaimable.toFixed(2));
      this.setClaimableNFTP(claimableNFTP);
    }
  }
}