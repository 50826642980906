

import { VarsStore } from "../@state/vars/varsStore";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { UserStore } from "../@state/user/userStore";
import { FirebaseStore } from "../@state/firebase/firebaseStore";
import { ModalStore } from "../@state/modal/modalStore";
import { Web3Store } from "../@state/web3/web3Store";
import { NFTClubStore } from "../@state/nft-club/nftClubStore";
import { WalletStore } from "../@state/wallet/walletStore";
import { NFTsStore } from "../@state/nfts/nftsStore";
import { NFTsForSaleStore } from "../@state/nfts-for-sale/nftsForSaleStore";
import { WalletStoreFlare } from "../@state/wallet/walletStoreFlare";
import { NFTClubStoreFlare } from "../@state/nft-club/nftClubStoreFlare";

class AppState {
  id = uuidv4();
  vars: VarsStore;
  web3: Web3Store;
  user: UserStore;
  fb: FirebaseStore;
  wallet: WalletStore;
  walletFlare: WalletStoreFlare;
  modal: ModalStore;
  nftClub: NFTClubStore;
  nftClubFlare: NFTClubStoreFlare;
  nfts: NFTsStore;
  forSale: NFTsForSaleStore;

  constructor() {
    makeAutoObservable(this);
    this.vars = new VarsStore();
    this.web3 = new Web3Store();
    this.fb = new FirebaseStore();
    this.wallet = new WalletStore();
    this.walletFlare = new WalletStoreFlare();
    this.user = new UserStore();
    this.modal = new ModalStore();
    this.nfts = new NFTsStore();
    this.forSale = new NFTsForSaleStore();
    this.nftClub = new NFTClubStore();
    this.nftClubFlare = new NFTClubStoreFlare();
  }
}

const app = new AppState();
export default app;
