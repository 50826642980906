import { ethers } from 'ethers';
import React, { useState } from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';

const TransferModal = (): JSX.Element => {
  const [toAddress, setToAddress] = useState('');
  const [qty, setQty] = useState('');

  const handleTransferClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    try {
      const abi = ['function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) public'];
      const tokenContract = new ethers.Contract(app.nfts.active.contractAddress, abi, prov.getSigner());
      await tokenContract.safeTransferFrom(app.user.address, toAddress, app.nfts.active.tokenId, qty, []);
      //console.log('result ', result)
      app.modal.closeModal();

    } catch (error) {
      console.log('error ', error);
    }
  };

  return (
    <Modal className="Modal darkModal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Transfer</h4>
        <div className="available-amount">Available: <strong>{app.nfts.active.qtyOwned} Qty</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to transfer <strong>{qty} NFT</strong> to <strong>{toAddress}</strong>
        </p>

        <div className="inputlines">
          <FormGroup>
            <label>Transfer to Address</label>
            <Row>
              <Col sm="12">
                <Input placeholder="Address" id="amount" type="text" value={toAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToAddress(e.target.value)} />
              </Col>
            </Row>
            <label>Quantity to transfer</label>
            <Row>
              <Col sm="12">
                <Input placeholder="Quantity" id="qty" type="text" value={qty} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQty(e.target.value)} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div>
          <div className="modaldetails small">
            <span>
              <p>
                Transferring this NFT will make it <strong>inaccessible to you and you will not be able to recover it</strong>.
              </p>
            </span>
          </div>
        </div>


      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        {/* <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApproveAllClicked(e)}>
          Approve All
        </Button> */}
        <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleTransferClicked(e)}>
          Transfer
        </Button>
      </div>
    </Modal>
  );
};
export default TransferModal;
