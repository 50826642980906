import { ethers } from "ethers";
import app from "../../app";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable } from "mobx";
import { TokenListing } from "../token-listing/TokenListing";


export class BestNFT {
  id = uuidv4();
  // contractAddress = '0x1646ac040284DA5878DD85DEfb07ce4eB1E32C26';
  marketplaceAddress = '0x3eD6b9037EFe4f8eFf52eB161eb2E9Ef7C52362d';
  marketplaceId: number
  tokenId?: number
  userAskingPrice = 0;
  userOwnedQty = 0;
  hasActiveListing = false;
  userForSaleQty = 0;
  userListingIndex = 0;
  lowestListingIndex = 0;
  totalForSaleQty = 0;
  userToLoad: string;
  listOfListings: TokenListing[] = [];

  constructor(marketplaceId: number, userToLoad: string) {
    makeAutoObservable(this);
    this.marketplaceId = marketplaceId;
    this.userToLoad = userToLoad;
  }

  async loadNFTDetails(): Promise<void> {
    await this.loadContractDetails();
    await this.loadTotalForSale();
    //await this.loadListings();
    await this.loadUserOwnedAmount();
  }

  getLowestPriceListing(): TokenListing | undefined {
    let lowestIndex = 0;
    if (this.listOfListings.length > 0) {
      let price = this.listOfListings[0].listedPrice;
      for (let i = 0; i < this.listOfListings.length; i++) {
        const newPriceToCheck = this.listOfListings[i].listedPrice;
        if (newPriceToCheck < price) {
          price = newPriceToCheck;
          lowestIndex = i;
        }
      }
      this.lowestListingIndex = lowestIndex;
      return this.listOfListings[lowestIndex];
    }
  }

  async loadContractDetails(): Promise<void> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function getTokenContractTokenId(uint256 _marketPlaceId) public view returns(uint256)'];
      try {
        const tokenContract = new ethers.Contract(this.marketplaceAddress, abi, prov);
        const tokenIdResult = await tokenContract.getTokenContractTokenId(this.marketplaceId);
        this.tokenId = tokenIdResult.toNumber();
      } catch (error) {
        console.log('Error ', error);
      }
    }
  }
  async loadTotalForSale(): Promise<void> {
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = ['function getTotalForSale(uint256 _marketPlaceId) public view returns(uint256)'];
      const marketplace = new ethers.Contract(this.marketplaceAddress, abi, prov);
      try {
        const totalForSaleResult = await marketplace.getTotalForSale(this.marketplaceId);
        this.totalForSaleQty = totalForSaleResult.toNumber();
      } catch (error) {
        console.log('Error ', error);
      }
    }
  }
  async loadListings(): Promise<void> {
    let forSaleAmount = 0;
    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const abi = [
        'function getListingSeller(uint256 _marketPlaceId, uint256 _listingIndex) public view returns(address)',
        'function getTotalForSaleByIndex(uint256 _marketPlaceId, uint256 _listingIndex) public view returns(uint256)',
        'function getListingPrice(uint256 _marketPlaceId, uint256 _listingIndex) public view returns(uint256)'
      ];
      for (let i = 0; forSaleAmount < this.totalForSaleQty; i++) {
        try {
          const tokenContract = new ethers.Contract(this.marketplaceAddress, abi, prov);
          const listingAddress = await tokenContract.getListingSeller(this.marketplaceId, i);
          const listingQty = await tokenContract.getTotalForSaleByIndex(this.marketplaceId, i);
          const listingPrice = await tokenContract.getListingPrice(this.marketplaceId, i);
          const formattedListingPrice = app.web3.convertWEIToETH(listingPrice);
          const tokenListing = new TokenListing(this.marketplaceId, listingAddress);
          tokenListing.listedQty = listingQty.toNumber();
          tokenListing.listedPrice = parseFloat(formattedListingPrice);
          if (listingAddress.toLowerCase() === this.userToLoad.toLowerCase()) {
            this.userListingIndex = i;
            this.userForSaleQty = listingQty.toNumber();
            this.userAskingPrice = parseFloat(formattedListingPrice);
          }
          this.listOfListings.push(tokenListing);
          const qtyToAdd: number = listingQty.toNumber();
          forSaleAmount = forSaleAmount + qtyToAdd;
        } catch (error) {
          console.log('Error ', error);
        }
      }
    }
  }


  async loadUserOwnedAmount(): Promise<void> {
    if (window.ethereum && app.user.address) {
      //  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      //    const abi = ['function balanceOf(address account, uint256 id) public view returns (uint256)'];
      //  if (this.userToLoad && this.contractAddress && window.ethereum) {
      //     const tokenContract = new ethers.Contract(this.contractAddress, abi, prov);
      //  const balanceResult = await tokenContract.balanceOf(this.userToLoad, this.tokenId);
      //  this.userOwnedQty = balanceResult.toNumber();
      //   }
    }
  }
}


export class BestNFTFactory {

  static async fromMarketplaceId(marketplaceId: number, userToLoad: string): Promise<BestNFT> {
    const nftToReturn = new BestNFT(marketplaceId, userToLoad);
    await nftToReturn.loadNFTDetails();

    return nftToReturn;
  }

}
